import React, {ChangeEvent, Component, FormEvent} from "react";
import classes from "./Provisionning.module.css"
import {Trans} from "react-i18next"
import { getClientUUID } from "../../api/Auth";
import { addSim, editSim } from "../../api/ProvisionningRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSimCard } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import { Sim } from "../../models/Sim";
import { ApiDataResponse } from "../../api/Request";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface IState {
    sim: Sim,
    creationRequestInProgress: boolean,
}

interface IProps {
    device?: Sim
}

class SimProvisionning extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            creationRequestInProgress: false,
            sim: props.device !== undefined ? props.device : {
                brand: "Prixtel",
                device_type: "sim",
                gsi1sk: "26C7042F-BCE8-4C6D-9CF8-CAA2A5F6EB92|7A44E7EE-6AE3-4D70-9239-B50E7CA4B756|de8beba4-e112-4ae3-9836-6125b7680384",
                isDeployed: true,
                lastIndex: 0,
                log_type: "REALTIME",
                model: "nano",
                parent_key: "none",
                pk: "",
                reference: "",
                searchRef: "",
                serial: "",
                sk: "sim|" + getClientUUID(),
                status: "IN_STOCK",
                statusUpdatedOn: "",
                phonenumber: "",
                pin: "",
                puk1: "",
                puk2: "",
                lastReceivedEvent: "",
                billingDay: 28,
            }
        }
    }

    onCreateClick = async(event: FormEvent) => {
        event.preventDefault()

        if(this.state.sim.billingDay < 1 || this.state.sim.billingDay > 31){
            alert("invalid billing day")
            return
        }

        await this.setState({creationRequestInProgress: true})
        let response: ApiDataResponse<object>|undefined = undefined
        if(this.props.device){
            response = await editSim(this.state.sim)
        }else {
            response = await addSim(this.state.sim)
        }
        if(response.error){
            toast(this.props.device ? "Erreur lors de l'édition de la SIM" : "Erreur lors de la création de la SIM", {
                type: "error"
            })
        }else {
            toast("SIM ajoutée au référentiel", {
                type: "success"
            })
            
            const deviceCopy = {...this.state.sim}
            deviceCopy.serial = ""
            await this.setState({sim: deviceCopy})
        }
        
        await this.setState({creationRequestInProgress: false})
    }

    onSerialChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.serial = value
        if(this.props.device === undefined){
            simCopy.reference = `sim-${value}`
            simCopy.searchRef = `sim-${value}`
        }
        this.setState({
            sim: simCopy
        })
    }

    
    onBrandChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.brand = value
        this.setState({
            sim: simCopy
        })
    }
    
    onBillingDayChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.billingDay = parseInt(value)
        this.setState({
            sim: simCopy
        })
    }

    onModelChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.model = value
        this.setState({
            sim: simCopy
        })
    }

    onPhoneNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.phonenumber = value
        this.setState({
            sim: simCopy
        })
    }

    onPinChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.pin = value
        this.setState({
            sim: simCopy
        })
    }

    onPathChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.gsi1sk = value
        this.setState({
            sim: simCopy
        })
    }

    onPuk1Changed = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.puk1 = value
        this.setState({
            sim: simCopy
        })
    }
    
    onPuk2Changed = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.puk2 = value
        this.setState({
            sim: simCopy
        })
    }
    
    onLogtypeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.log_type = value as "OFFLINE" | "REALTIME"
        this.setState({
            sim: simCopy
        })
    }

    onReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.reference = value
        this.setState({
            sim: simCopy
        })
    }
    
    onSearchReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const simCopy = {...this.state.sim}
        simCopy.searchRef = value
        this.setState({
            sim: simCopy
        })
    }

    render(){
        return (
            <div className={classes.ProvisionningContainer}>
                <h4><FontAwesomeIcon icon={faSimCard}/> {this.props.device === undefined ? "Ajout d'une SIM au référentiel" : "Édition d'une SIM"}</h4>
                <form onSubmit={this.onCreateClick}>
                    <div style={{display: "flex", flexDirection: "column"}}>

                        <table className={classes.ProvisionningFieldsTable}>
                            <tr>
                                <td><label><Trans>Serial</Trans> : </label></td>
                                <td><input required onChange={this.onSerialChanged} value={this.state.sim.serial} type="text"/></td>
                            </tr>

                            
                            <tr>
                                <td><label><Trans>Brand</Trans> : </label></td>
                                <td><input required onChange={this.onBrandChanged} value={this.state.sim.brand} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Model</Trans> : </label></td>
                                <td><input required onChange={this.onModelChanged} value={this.state.sim.model} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Phone number</Trans> : </label></td>
                                <td><input required onChange={this.onPhoneNumberChanged} value={this.state.sim.phonenumber} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Pin</Trans> : </label></td>
                                <td><input required onChange={this.onPinChanged} value={this.state.sim.pin} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Puk 1</Trans> : </label></td>
                                <td><input required onChange={this.onPuk1Changed} value={this.state.sim.puk1} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Puk 2</Trans> : </label></td>
                                <td><input required onChange={this.onPuk2Changed} value={this.state.sim.puk2} type="text"/></td>
                            </tr>


                            <tr>
                                <td><label><Trans>LogType</Trans> : </label></td>
                                <td><select onChange={this.onLogtypeChanged}>
                                    <option key="realtime" value="REALTIME">Realtime</option>
                                    <option key="offline" value="OFFLINE">Offline</option>
                                </select>
                                </td>
                            </tr>

                            <tr>
                                <td><label><Trans>Billing day</Trans> : </label></td>
                                <td><input required onChange={this.onBillingDayChanged} value={this.state.sim.billingDay} type="number" min={1} max={31}/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Réserve</Trans> : </label></td>
                                <td><input required onChange={this.onPathChanged} value={this.state.sim.gsi1sk} type="text"/></td>
                            </tr>

                            {
                                this.props.device ? 
                                <>
                                    <tr>
                                        <td><label><Trans>Reference</Trans> : </label></td>
                                        <td><input required onChange={this.onReferenceChanged} value={this.state.sim.reference} type="text"/></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><label><Trans>Search reference</Trans> : </label></td>
                                        <td><input required onChange={this.onSearchReferenceChanged} value={this.state.sim.searchRef} type="text"/></td>
                                    </tr>
                                </>
                                : null
                            }

                        </table>

                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.creationRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={this.props.device ? classes.ProvisionningEditButton : classes.ProvisionningValidateButton} type={"submit"}><FontAwesomeIcon icon={this.props.device ? faPen: faPlus}/> <Trans>{this.props.device ? "Edit" : "Create"}</Trans></button>}

                    </div>
                </form>
                <ToastContainer/>
            </div>
        )
    }
}

export default SimProvisionning