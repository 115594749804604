import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"
import { InoutDataApiResponse } from "../../../api/MaintenanceRequests"

export const getInOutDataEvolutionDataset = (data: InoutDataApiResponse): EvolutionDataset => {
    const inDataset: ChartDataSets = {
        borderColor: "#FF3333",
        backgroundColor: "rgba(220,4,151,0)",
        label: "Tx",
        lineTension: 0,
        data: []
    };

    const outDataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "Rx",
        lineTension: 0,
        data: []
    };
    
    const dates:Array<Date> = new Array<Date>()
    
    data.forEach(it => {        
        const dateString:string = it.date
        const date:Date = new Date(dateString)
        
        dates.push(date);
    
        const inData = inDataset.data as ChartPoint[]
        const outData = outDataset.data as ChartPoint[]
        inData.push({
            x: date,
            y: it.rx
        })

        outData.push({
            x: date,
            y: it.tx
        })
        
    })

    return {
        datasets: [inDataset, outDataset],
        dates: dates,
        error: undefined,
        labels: undefined,
        maxValue: undefined
    }
}