import { ChartDataSets, ChartPoint } from "chart.js";
import { DataConsumptionEvolutionResponse } from "../../../api/GSMRequests";
import { EvolutionDataset } from "../EvolutionDataset";

export const getDataConsumptionEvolutionDataset = (data: DataConsumptionEvolutionResponse):EvolutionDataset => {
    
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "Data",
        lineTension: 0,
        data: []
    };
    
    const dates:Array<Date> = new Array<Date>()
   
    
    data.forEach(it => {

        const dateString:string = it.date
        const date:Date = new Date(dateString)
        
        dates.push(date);
    
        (dataset.data as ChartPoint[]).push({
            x: date,
            y: Math.round((it.totalUsed + Number.EPSILON) * 100) / 100
        })
        
    })

    return {
        datasets: [dataset],
        dates: dates,
        error: undefined,
        labels: undefined,
        maxValue: undefined
    }
}