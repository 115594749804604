import { useEffect, useState } from "react"
import classes from  "../stats/Statistics.module.css"
import { Tabs, Interval, TabItem } from "../navigation/tabs/Tabs";
import {subDays} from "date-fns"
import Tree from "../navigation/tree/Tree";
import { Area } from "../navigation/tree/Area";
import { getRangeEndDate, getRangeStartDate } from "../helpers/Storage";

interface IProps {
    children: React.ReactNode
    onSelectArea: (area: Area, nodeId: string, nodePath: string) => void
    items: Array<{label:string, path: string}>
    onIntervalChanged: (interval: Interval) => void
    onPeriodChanged: (startDateTimestamp: number, endDateTimestamp: number) => void
}

const WithTreeTab = (props: IProps) => {
    
    
    const endDate = getRangeEndDate() === null ? Date.now() : getRangeEndDate() as number
    const startDate = getRangeStartDate() === null ? subDays(endDate, 1) : new Date(getRangeStartDate() as number)
    startDate.setHours(0,0,0,0);

    const [selectedTab, setSelectedTab] = useState<TabItem|undefined>(props.items.length > 0 ? props.items[0] : undefined)
    const [startPeriodTimestamp, setStartPeriodTimestamp] = useState<number>(startDate.getTime())
    const [endPeriodTimestamp, setEndPeriodTimestamp] = useState<number>(endDate)

    const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [selectedNodePath, setSelectedNodePath] = useState<string|undefined>(undefined)
    const [selectedArea, setSelectedArea] = useState<Area|undefined>(undefined)

    const [interval, setInterval] = useState<Interval>(Interval.DAY)
    
    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        setSelectedNodePath(nodePath)

        props.onSelectArea(area, nodeId, nodePath)
    }

    useEffect(() => {
        if(props.items.length === 0){
            return
        }
        const tab = props.items.find(it => window.location.pathname === "/supervision/stats/" + it.path) ?? props.items[0]
        if(JSON.stringify(selectedTab) !== JSON.stringify(tab)){
            setSelectedTab(tab)
        }
    }, [])

    return (
        <>            
            <div className={classes.StatisticsTreeContainer}>
                <Tree
                    autoExpandAllAreas={true}
                    isAreaManagement={false}
                    hideVisibleNodes={true}
                    onFactoriesLoaded={() => console.log("factories loaded")}
                    onModacFetched={() => {console.log("modac fetched")}}
                    onSelectModac={() => {console.log("select modac")}}
                    alreadySelectedNodeId={selectedNodeId}
                    onSelect={onSelectArea} />
            </div>

            <div className={classes.StatisticsTabsContainer}>
                <Tabs
                    interval={interval}
                    onSelect={(newSelectedTab:TabItem) => setSelectedTab(newSelectedTab)}
                    onIntervalChanged={(interval) => {
                        setInterval(interval)
                        props.onIntervalChanged(interval)
                    }}
                    onPeriodChange={(dates) => {
                        setStartPeriodTimestamp(dates[0])
                        setEndPeriodTimestamp(dates[1].getTime() + (24 * 60 * 60 * 1000) - 1) // "Returns date at 00h00, so we had seconds to get same date at 23h59
                        
                        props.onPeriodChanged(dates[0], dates[1].getTime() + (24 * 60 * 60 * 1000) - 1)
                        
                    }}
                    startDatePeriodTimestamp={startPeriodTimestamp}
                    endDatePeriodTimestamp={endPeriodTimestamp}
                    selectedTab={selectedTab}
                    items={props.items}
                />
            </div>

            {selectedNodePath !== undefined && selectedArea !== undefined ?
                <div className={classes.StatisticsContainer}>
                    {props.children}
                </div>
                : undefined
            }
        </>    
    )
}

export default WithTreeTab