import { ChartDataSets, ChartPoint } from "chart.js";
import { GSMSignalEvolutionResponse } from "../../../api/GSMRequests";
import { EvolutionDataset } from "../EvolutionDataset";

export const getGSMSignalEvolutionDataset = (data: GSMSignalEvolutionResponse):EvolutionDataset => {
    
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "RSSI (dBm)",
        data: []
    };
    
    const dates:Array<Date> = new Array<Date>()
    
    data.forEach(it => {        
        const dateString:string = it.date
        const date:Date = new Date(dateString)
    
        if(it.value !== null){
            dates.push(date);
        
            (dataset.data as ChartPoint[]).push({
                x: date,
                y: Math.round((it.value + Number.EPSILON) * 100) / 100
            })
        }
    })


    return {
        datasets: [dataset],
        dates: dates,
        error: undefined,
        labels: undefined,
        maxValue: undefined
    }
}