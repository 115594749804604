import { ServiceUsageAmountResponse } from "../../../api/PortalRequests"
import { MaterialColors } from "../../../helpers/Colors"
import { EvolutionDataset } from "../EvolutionDataset"

export const getServicesAmountDataset = (data: ServiceUsageAmountResponse): EvolutionDataset => {
    const labels:Array<string> = new Array<string>()
    
    const dataset = {
        borderColor: "#2196f3",
        backgroundColor: MaterialColors,
        label: "Utilisations",
        data: data.map(it => it.count)
    }
    
    data.forEach(serviceUsage => {  
        labels.push(serviceUsage.service)        
    })

    return {
        datasets: [dataset],
        dates: [],
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}