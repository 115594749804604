import React, {useEffect, useState} from "react"
import { Chart, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-zoom';
import {Trans} from "react-i18next"

import classes from "./Graph.module.css"
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import {Interval} from "../navigation/tabs/Tabs"

import {graphProps as dataUsageEvolutionProps} from "./graphprops/gsm/DataConsumptionProps";
import {graphProps as gsmSignalEvolutionProps} from "./graphprops/gsm/GSMSignalProps";
import {graphProps as servicesUsageAmountProps} from "./graphprops/portal/ServicesUsageAmountProps";
import {graphProps as sessionsCountEvolutionProps} from "./graphprops/portal/SessionsCountEvolutionProps";
import {graphProps as servicesUsageAmountEvolutionProps} from "./graphprops/portal/ServicesUsageAmountEvolutionProps";
import {graphProps as browsersDistributionProps} from "./graphprops/maintenance/BrowsersDistributionProps";
import {graphProps as cpuEvolutionProps} from "./graphprops/maintenance/CPUEvolutionProps";
import {graphProps as inoutDataEvolutionProps} from "./graphprops/maintenance/InoutDataEvolutionProps";
import {graphProps as freeMemoryEvolutionProps} from "./graphprops/maintenance/FreeMemoryEvolutionProps";
import {graphProps as temperatureEvolutionProps} from "./graphprops/maintenance/TemperatureEvolutionProps";


import { getDataConsumptionEvolutionDataset } from "./datasets/gsm/DataConsumptionEvolutionDataset";
import { getGSMSignalEvolutionDataset } from "./datasets/gsm/GSMSignalEvolutionDataset";
import { getServicesAmountDataset } from "./datasets/portal/ServicesUsageAmountDataset";
import { getServicesUsageEvolutionDataset } from "./datasets/portal/ServicesUsageEvolutionDataset";
import { getSessionsAmountEvolutionDataset } from "./datasets/portal/SessionsAmountEvolutionDataset";
import { getBrowsersRepartitionDataset } from "./datasets/maintenance/BrowsersDistributionDataset";
import { getCPUEvolutionDataset } from "./datasets/maintenance/CPUEvolutionDataset";
import { getInOutDataEvolutionDataset } from "./datasets/maintenance/InoutDataEvolutionDataset";
import { getFreeMemoryEvolutionDataset } from "./datasets/maintenance/FreeMemoryEvolutionDataset";
import { getTemperatureEvolutionDataset } from "./datasets/maintenance/TemperatureEvolutionDataset";

import { getDataConsumptionEvolution, getGSMSignalEvolution } from "../api/GSMRequests";

import { ApiDataResponse } from "../api/Request";
import { getServicesUsage, getServicesUsageAmountEvolution, getSessionsAmountEvolution } from "../api/PortalRequests";
import { getBrowsersRepartition, getFreeMemoryEvolution, getInOutDataEvolution, getTemperatureEvolution } from "../api/MaintenanceRequests";

export enum GraphType {
    // Portal graphs
    SESSIONS_COUNT_EVOLUTION,
    SERVICES_USAGE_EVOLUTION,
    SERVICES_USAGE,
    // GSM graphs
    SIGNAL_QUALITY_EVOLUTION,
    DATA_USAGE_EVOLUTION,
    // Maintenance graphs
    BROWSERS_DISTRIBUTION,
    IN_OUT_DATA_EVOLUTION,
    FREE_MEMORY_EVOLUTION,
    TEMPERATURE_EVOLUTION

}

interface IProps {
    graphType: GraphType

    interval: Interval
    startDatePeriodTimestamp: number,
    endDatePeriodTimestamp: number,

    nodePath: string,
    mediaspotSerial?:string

    preventUpdateAnimations: boolean
}

export const Graph = (props: IProps) => {

    const [isContentLoaded, setContentLoaded] = useState<boolean>(false) // Specify if content is loading
    const [error, setError] = useState<string|undefined>(undefined) // Specify if request failed
    const [noData, setNoData] = useState<boolean>(false) // Specify if response is OK but with no data
    const [apiResponseData, setApiResponseData] = useState<undefined|ApiDataResponse<any>>(undefined)

    let chart: Chart|undefined = undefined
    
    const [chartRef, _] = useState<React.RefObject<HTMLCanvasElement>>(React.createRef())

    const setGraphData = () => {
        let resp = undefined;
        switch (props.graphType) {
            case GraphType.DATA_USAGE_EVOLUTION:{ resp = getDataConsumptionEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.SIGNAL_QUALITY_EVOLUTION:{ resp = getGSMSignalEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.SERVICES_USAGE:{ resp = getServicesAmountDataset(apiResponseData?.data); break;}
            case GraphType.SERVICES_USAGE_EVOLUTION:{ resp = getServicesUsageEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.SESSIONS_COUNT_EVOLUTION:{ resp = getSessionsAmountEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.BROWSERS_DISTRIBUTION:{ resp = getBrowsersRepartitionDataset(apiResponseData?.data); break;}
            case GraphType.IN_OUT_DATA_EVOLUTION:{ resp = getInOutDataEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.FREE_MEMORY_EVOLUTION:{ resp = getFreeMemoryEvolutionDataset(apiResponseData?.data); break;}
            case GraphType.TEMPERATURE_EVOLUTION:{ resp = getTemperatureEvolutionDataset(apiResponseData?.data); break;}
            default: resp = undefined
        }
        //TODO: set error if resp is undefined
        if(resp !== undefined && resp.error !== undefined){
            setError("invalid dataset")
            return;
        }
        if(resp !== undefined){
            setChart(chartRef, resp.datasets, resp.dates, resp.labels, resp.maxValue)
        }
    }

    
    const loadGraph = async() => {
        setContentLoaded(false)
        setError(undefined)
        setNoData(false)

        
        let data:ApiDataResponse<any>|undefined = undefined;
        switch (props.graphType) {
            case GraphType.DATA_USAGE_EVOLUTION:{ data = await getDataConsumptionEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.mediaspotSerial ?? ""); break; }
            case GraphType.SIGNAL_QUALITY_EVOLUTION:{ data = await getGSMSignalEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.SERVICES_USAGE:{ data = await getServicesUsage(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.SERVICES_USAGE_EVOLUTION:{ data = await getServicesUsageAmountEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.SESSIONS_COUNT_EVOLUTION:{ data = await getSessionsAmountEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.BROWSERS_DISTRIBUTION:{ data = await getBrowsersRepartition(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.IN_OUT_DATA_EVOLUTION:{ data = await getInOutDataEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.FREE_MEMORY_EVOLUTION:{ data = await getFreeMemoryEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.nodePath, props.mediaspotSerial ?? ""); break; }
            case GraphType.TEMPERATURE_EVOLUTION:{ data = await getTemperatureEvolution(props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.interval, props.nodePath, props.mediaspotSerial ?? ""); break; }
            default: data = undefined
        }

        if(data === undefined ||  data.error !== undefined){
            //setError(data === undefined ? new Error("No data response") : new Error(data.error ?? ""))
            setError(data === undefined ? "No data response" : data.error)
            return
        }
        
        await setApiResponseData(data)
        await setError(undefined)
    }


    useEffect(() => {
        if(apiResponseData){
            setGraphData()
        }
    }, [apiResponseData])

    // Reload each time period/nodepath/interval are updated
    useEffect(() => {
        loadGraph()
    }, [props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp, props.nodePath])


    const setChart = (ref: React.RefObject<HTMLCanvasElement>, datasets: ChartDataSets[], dates: Date[], labels?: string[]|Array<string[]>, maxValue?: number) => {
        const myChartRef = ref.current?.getContext("2d");
        if(chart){
            chart.destroy()
        }
        
        console.log("data graph", datasets)
        if(datasets.find(it => it.data !== undefined && it.data.length !== 0) === undefined){
            setNoData(true)
            setContentLoaded(true)
            return;
        }
        
        switch (props.graphType) {
            case GraphType.DATA_USAGE_EVOLUTION: { chart = new Chart(myChartRef!, dataUsageEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.SIGNAL_QUALITY_EVOLUTION: { chart = new Chart(myChartRef!, gsmSignalEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.SERVICES_USAGE: { chart = new Chart(myChartRef!, servicesUsageAmountProps()); break; }
            case GraphType.SERVICES_USAGE_EVOLUTION: { chart = new Chart(myChartRef!, servicesUsageAmountEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.SESSIONS_COUNT_EVOLUTION: { chart = new Chart(myChartRef!, sessionsCountEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.BROWSERS_DISTRIBUTION: { chart = new Chart(myChartRef!, browsersDistributionProps()); break; }
            case GraphType.IN_OUT_DATA_EVOLUTION: { chart = new Chart(myChartRef!, inoutDataEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.FREE_MEMORY_EVOLUTION: { chart = new Chart(myChartRef!, freeMemoryEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            case GraphType.TEMPERATURE_EVOLUTION: { chart = new Chart(myChartRef!, temperatureEvolutionProps(dates, props.interval, props.startDatePeriodTimestamp, props.endDatePeriodTimestamp)); break; }
            default: chart = undefined
        }

        if(chart !== undefined){
            chart.data.datasets = datasets
            chart.data.labels = labels

            if(props.preventUpdateAnimations === true && chart?.options.animation !== false && chart.options.animation?.duration){
                chart.options.animation.duration = 0
            }
            chart.update();
        }

        setContentLoaded(true)
    }

    const handlePress = (e:React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        e.stopPropagation();
    }

    return (
        <div className={classes.GraphContainer}>
            {isContentLoaded || error ? undefined :
                <div className={classes.GraphSpinnerContainer}>
                    <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                </div>
            }

            {noData ? <div className={classes.GraphSpinnerContainer}>
                <label><Trans>NoDataOnThisPeriod</Trans></label>
            </div> : undefined}

            {error === undefined ? undefined :
                <div className={classes.GraphSpinnerContainer}>
                    <label><Trans>AnErrorOccurredDuringLoading</Trans>...</label>
                    <br />
                    <button className={classes.RetryButton} onClick={loadGraph}><FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"}/>&nbsp;&nbsp;<Trans>Reload</Trans></button>
                </div>}
            <canvas onMouseDown={e => handlePress(e)} ref={chartRef}/>
        </div>
    )
}