import { useState } from "react"
import classes from "../../stats/Statistics.module.css"
import { Interval } from "../../navigation/tabs/Tabs";
import {subDays} from "date-fns"
import { Area } from "../../navigation/tree/Area";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import WithTreeTab from "../WithTreeTab";
import { MediasRanking } from "../../stats/medias/mediasranking/MediasRanking";
import { HTTPErrors } from "../../stats/maintenance/httperrors/HTTPErrors";
import { Graph, GraphType } from "../../graph/Graph";
import MultiLevelPieChart from "../../graph/MultiLevelPieChart"
import ReactTooltip from 'react-tooltip';
import { getRangeEndDate, getRangeStartDate } from "../../helpers/Storage";

const PortalServices = () => {

    const endDate = getRangeEndDate() === null ? Date.now() : getRangeEndDate() as number
    const startDate = getRangeStartDate() === null ? subDays(endDate, 1) : new Date(getRangeStartDate() as number)
    startDate.setHours(0,0,0,0);

    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState<Layouts>({})

    // const [selectedTab, setSelectedTab] = useState<TabItem>(items[0])
    const [startPeriodTimestamp, setStartPeriodTimestamp] = useState<number>(startDate.getTime())
    const [endPeriodTimestamp, setEndPeriodTimestamp] = useState<number>(endDate)

    const [_, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [selectedNodePath, setSelectedNodePath] = useState<string|undefined>(undefined)
    const [selectedArea, setSelectedArea] = useState<Area|undefined>(undefined)

    const [interval, setInterval] = useState<Interval>(Interval.DAY)

    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        setSelectedNodePath(nodePath)
    }

    return (
        <WithTreeTab
            items={[]}
            onPeriodChanged={(startDateTimestamp, endDateTimestamp) => {
                setStartPeriodTimestamp(new Date(startDateTimestamp).getTime())
                setEndPeriodTimestamp(new Date(endDateTimestamp).getTime())}}
            onIntervalChanged={(newInterval) => setInterval(newInterval)}
            onSelectArea={onSelectArea}>


                {selectedNodePath !== undefined && selectedArea !== undefined ? (
                    <ResponsiveReactGridLayout
                        rowHeight={100}
                        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                        layouts={layouts}
                        onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                            // Check to prevent infinite refresh
                            if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                                setLayouts(allLayouts)
                            }
                        }}>

                        <div className={classes.ChartContainer} key={"stat2"} data-grid={{ w: 6, h: 3, x: 0, y: 0, static: true }}>
                                <label>Classement des médias</label>
                                <div className={classes.ChartTitleSeparator}/>
                                <div className={classes.ChartGraphContainer}>
                                    <div className={classes.StatisticsGroupContent}>
                                        <MediasRanking
                                            area={selectedArea!}
                                            nodePath={selectedNodePath!}
                                            startDateTimestamp={startPeriodTimestamp}
                                            endDateTimestamp={endPeriodTimestamp}
                                            interval={interval}/>
                                    </div>
                                </div>
                        </div>
                        <div className={classes.ChartContainer} key={"http_errors"} data-grid={{ w: 6, h: 3, x: 6, y: 0, static: true }}>
                            <label>Erreurs de communications HTTP</label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>            
                                <div className={classes.StatisticsGroupContent}>
                                    <HTTPErrors
                                        area={selectedArea!}
                                        nodePath={selectedNodePath!}
                                        startDateTimestamp={startPeriodTimestamp}
                                        endDateTimestamp={endPeriodTimestamp}/>
                                </div>
                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"browsers_repartition"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                            <label>Répartition des navigateurs</label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer} style={{padding: 0}}>            
                                <div className={classes.StatisticsGroupContent}>
                                    {/* <Graph
                                        preventUpdateAnimations={false}
                                        graphType={GraphType.BROWSERS_DISTRIBUTION}
                                        startDatePeriodTimestamp={startPeriodTimestamp}
                                        endDatePeriodTimestamp={endPeriodTimestamp}
                                        interval={interval}
                                        mediaspotSerial={selectedArea.mediaspot?.serial}
                                        nodePath={selectedNodePath}/> */}
                                        <MultiLevelPieChart
                                            startDateTimestamp={startPeriodTimestamp}
                                            endDateTimestamp={endPeriodTimestamp}
                                            nodePath={selectedNodePath!} />
                                </div>
                            </div>
                        </div>




                        <div className={classes.ChartContainer} key={"services_usage_evolution_stats"} data-grid={{ w: 6, h: 3, x: 6, y: 3, static: true }}>
                        <label>Évolution de l'utilisation des services</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>            
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.SERVICES_USAGE_EVOLUTION}
                                startDatePeriodTimestamp={startPeriodTimestamp}
                                endDatePeriodTimestamp={endPeriodTimestamp}
                                interval={interval}
                                nodePath={selectedNodePath}
                                mediaspotSerial={selectedArea.mediaspot?.serial}/>
                        </div>
                        </div>

                        <div className={classes.ChartContainer} key={"services_usage_stats"} data-grid={{ w: 6, h: 3, x: 0, y: 6, static: true }}>
                                <label>Utilisation des services</label>
                                <div className={classes.ChartTitleSeparator}/>
                                <div className={classes.ChartGraphContainer}>            
                                    <Graph
                                        preventUpdateAnimations={false}
                                        graphType={GraphType.SERVICES_USAGE}
                                        startDatePeriodTimestamp={startPeriodTimestamp}
                                        endDatePeriodTimestamp={endPeriodTimestamp}
                                        interval={interval}
                                        nodePath={selectedNodePath}
                                        mediaspotSerial={selectedArea.mediaspot?.serial}/>
                                </div>
                        </div>
                    </ResponsiveReactGridLayout>)
                : <div key="empty_0"/> }
                <ReactTooltip />

        </WithTreeTab>    
    )
}

export default PortalServices