export const NodeType = {
    AREA: "area",
    MEDIASPOT: "mediaspot",
    ROUTER: "router",
    SIM: "sim",
    MODAC: "modac",
    RADIAMETER: "radiameter",
    PROBE: "probe",
}


export const getNodeTypeFromString = (typeString:string) => {
    if(typeString === undefined){
        return NodeType.AREA
    }
    switch (typeString.toLowerCase()){
        case NodeType.MEDIASPOT: return NodeType.MEDIASPOT
        case NodeType.ROUTER: return NodeType.ROUTER
        case NodeType.SIM: return NodeType.SIM
        case NodeType.MODAC: return NodeType.MODAC
        case NodeType.RADIAMETER: return NodeType.RADIAMETER
        case NodeType.PROBE: return NodeType.PROBE
        default: return NodeType.AREA
    }
}
