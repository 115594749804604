// ------------------
// ---- Requests ----
// ------------------

import { getClientUUID } from "./Auth"
import { post, ApiDataResponse } from "./Request"
import { getIntervalString, Interval } from "../navigation/tabs/Tabs"
import {subDays} from "date-fns"

/***
 * Get media ranking with medias/access count list
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial optional mediaspot serial
 */
export const getHTTPErrors = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<HTTPErrorsCountApiResponse>> => {
    const httpErrorsCountResponse = await post<HTTPErrorsCountApiResponse>("gethttperrorscount", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })

    return httpErrorsCountResponse
}

/***
 * Get clients browsers repartition usage for specific location
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial optional mediaspot serial to filter result on specific path for a specific mediaspot serial
 */
export const getBrowsersRepartition = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<BrowsersRepartitionApiResponse>> => {
    const httpErrorsCountResponse = await post<BrowsersRepartitionApiResponse>("getbrowsersdistribution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })

    return httpErrorsCountResponse
   
}



/***
 * Get list of most visited website domains with number of visits for each one
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial optional mediaspot serial to filter data on a node with a specific mediaspot (if multiple mediaspot have been associated to a node)
 */
export const getTopWebsites = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<TopWebsitesApiResponse>> => {
    const mostVisitedWebsitesResponse = await post<TopWebsitesApiResponse>("getmostvisitedwebsites", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })

    return mostVisitedWebsitesResponse
}

/***
 * Get in/out data evolution from date
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial optional mediaspot serial to filter data on a node with a specific mediaspot
 */
export const getInOutDataEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<InoutDataApiResponse>> => {
    return await post<InoutDataApiResponse>("getinouthotspottraffic", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        interval: getIntervalString(interval),
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
} 


/***
 * Get evolution of free memory from date
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial mediaspot serial
 * @return ApiDataResponse<FreeMemoryEvolutionApiResponse>
 */
export const getFreeMemoryEvolution = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial: string): Promise<ApiDataResponse<FreeMemoryEvolutionApiResponse>> => {
    const today = new Date(Date.now())
    let dates:string[] = []
    dates.push(today.toISOString())
    for(let i = 0; i < 20 ; i++){
        dates.push(subDays(today, i).toISOString())
    }
                
    return await new Promise(resolve => setTimeout(() => resolve(
        {
            error: undefined,
            data: dates.map(date => {
                return {
                    date: date,
                    freeMemory: 1000,
                }
            })
        }
    )))
}


/***
 * Get evolution of temperature
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial mediaspot serial
 * @return ApiDataResponse<TemperatureEvolutionApiResponse>
 */
 export const getTemperatureEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial: string): Promise<ApiDataResponse<TemperatureEvolutionApiResponse>> => {
    const temperatureEvolutionResponse = await post<TemperatureEvolutionApiResponse>("getmodemtemperatureevolution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        interval: getIntervalString(interval),
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
    return temperatureEvolutionResponse
} 


// -------------------
// ---- Types def ----
// -------------------

export type HTTPErrorsCountApiResponse = Array<{
    status: number,
    count: number
}>

export type BrowsersRepartitionApiResponse = Array<{
    browser: string,
    count: number,
    versions: Array<{
        version: string,
        count: number
    }>
}>

export type CPUEvolutionApiResponse = Array<{
    date: string,
    percent: number
}>

export type TopWebsitesApiResponse = Array<{
    website: string,
    count: number
}>

export type InoutDataApiResponse = Array<{
    date: string,
    tx: number,
    rx: number
}>

export type FreeMemoryEvolutionApiResponse = Array<{
    date: string,
    freeMemory: number
}>

export type TemperatureEvolutionApiResponse = Array<{
    date: string,
    temperature: number
}>