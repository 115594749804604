class Bus {
    private id: string
    private latitude: number
    private longitude: number
    private lastContact: Date|undefined


    constructor(id: string, lat: number, lng: number, lastContact: Date|undefined){
        this.id = id
        this.latitude = lat
        this.longitude = lng
        this.lastContact = lastContact
    }

    public getId(){
        return this.id
    }

    public getLatitude():number{
        return this.latitude
    }

    public getLongitude():number{
        return this.longitude
    }

    public getLastContact():Date|undefined {
        return this.lastContact
    }
}


export default Bus