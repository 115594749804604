//@ts-ignore
import { WifiInterfaces, WifiInterface, MediaspotStatusMonitorComponent, getMediaspot, WelcomePage, Firewall, ContentProviders, AdvancedMediaspotParameter, CustomJSONComponent, TasksManager, WebsocketSubscriptionType } from "@fastpoint/mediaspot-management"
import { Task } from "@fastpoint/mediaspot-management/dist/entities/Task"
import { TasksListener } from "@fastpoint/mediaspot-management/dist/helpers/TasksManager"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useEffect, useState } from "react"
import classes from "./MediaspotManagement.module.css"
import { useToasts } from 'react-toast-notifications';
import { getReadablePendingTaskDescription, getReadableTaskDescription } from "../../../helpers/mediaspot/MediaspotHelper"

interface IProps {
    mediaspotId: string
}

const MediaspotManagement = (props: IProps) => {
    
    const { addToast } = useToasts();

    const [mediaspot, setMediaspots] = useState<any|undefined>(undefined)
    const [contentProvidersExpanded, setContentProvidersExpanded] = useState(false)
    const [wifiInterfacesExpanded, setWifiInterfacesExpanded] = useState(false)
    const [firewallExpanded, setFirewallExpanded] = useState(false)
    const [welcomepageExpanded, setWelcomepageExpanded] = useState(false)
    const [advancedParametersExpanded, setAdvancedParametersExpanded] = useState(false)
    const [statusMonitorExpanded, setStatusMonitorExpanded] = useState(false)
    const [customJSONExpanded, setCustomJSONExpanded] = useState(false)

    const [tasks, setTasks] = useState<Task[]>([])
    const mediaspotSerial = props.mediaspotId

    const fetchMediaspots = async() => {
        const mediaspotResponse = await getMediaspot(mediaspotSerial)
        if(mediaspotResponse.result){
            setMediaspots(mediaspotResponse.result)
        }
        console.log(mediaspotResponse)
        
    }


    const tasksListener = new (class FPTaskListener implements TasksListener {
        onTasksUpdated(tasks: Task[]): void {
            console.log("onTasksUpdated")

            const mediaspotTasks = TasksManager.getInstance().getTasks(mediaspotSerial)
            setTasks(mediaspotTasks)
            // TODO: Reload mediaspot once tasks completed
            if(mediaspotTasks.length === 0){
                //await this.getMediaspotObject()
            }
        }
        onTaskAdded(task: Task): void {
            console.log("onTaskAdded")
        }
        onTaskDeleted(task: Task, completed: boolean): void {
            console.log("onTaskDeleted")
            if(completed){
                const parameterValues = task.parameterValues
                if(parameterValues.length > 0 && task.name === "setParameterValues"){
                    const parametersValueTask = task.parameterValues[0]
                    if(parametersValueTask.length >= 2){
                        const key = parametersValueTask[0]
                        const value = parametersValueTask[1]
                        const text = getReadableTaskDescription(key, value)
                        addToast(text, {
                            appearance: 'success',
                            autoDismiss: true
                        })
                    }
                }
            }else {
                const parameterValues = task.parameterValues
                if(parameterValues.length > 0 && task.name === "setParameterValues"){
                    const parametersValueTask = task.parameterValues[0]
                    if(parametersValueTask.length >= 2){
                        const key = parametersValueTask[0]
                        const value = parametersValueTask[1]
                        addToast(`${getReadablePendingTaskDescription(key, value)} annulée`, {
                            appearance: 'warning',
                            autoDismiss: true
                        })
                    }
                }
    
            }
        }
    })()

    
    
    // Reload mediaspot info on mediaspotId props changed
    useEffect(() => {
        console.log("useeffect, fetch mediapots")
        fetchMediaspots()
    }, [])


    useEffect(() => {
        console.log("subscribe to tr069 tasks")
        TasksManager.getInstance().susbcribe(WebsocketSubscriptionType.tr069_tasks, tasksListener)
        return () => {
            console.log("unsubscribe to tr069 tasks")
            TasksManager.getInstance().unsusbcribe(WebsocketSubscriptionType.tr069_tasks, tasksListener)
        }
    }, [])


    const onFirewallInfoUpdate = () => {
        //alert("firewall information update")
    }

    const onWifiInterfaceUpdate = (wifiInterfaces: WifiInterface[]) => {
        //alert("wifi interface update")
        console.log(wifiInterfaces)
    }

    const onDeleteTasks = () => {

    }

    return (
        <>
            {
                mediaspot !== undefined ? 
                    
                    <>
                      
                      <h4 className={classes.MediaspotManagementTitle}>Mediaspot {props.mediaspotId}</h4>

                        {(tasks !== undefined && tasks.length > 0) ?
                            <div className={classes.TasksContainer}>
                                <label data-text-color={"#000000"} data-place={"bottom"} data-class={classes.tooltip} data-border={true} data-border-color={"#c3bdb9"} data-background-color={"white"} data-multiline={true}>{tasks.length} {tasks.length === 1 ? "task" : "tasks"}</label>
                                <button onClick={onDeleteTasks}><FontAwesomeIcon icon={faTrash} color={"#FFFFFF"}/></button>
                            </div> : null}

                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setContentProvidersExpanded(!contentProvidersExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-photo-video fa-fw`}/> Content providers</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {contentProvidersExpanded ?
                                <div className={classes.GroupContent}>
                                    <ContentProviders
                                        addToast={() => {}}
                                        contentProviders={mediaspot.cp}
                                        deviceId={mediaspot.id}
                                    />
                                </div> : undefined}
                        </div>

                        {/* <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setWifiInterfacesExpanded(!wifiInterfacesExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-wifi fa-fw`}/> Interfaces Wifi</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {wifiInterfacesExpanded ?
                                <div className={classes.GroupContent}>
                                    <WifiInterfaces
                                        addToast={() => {}}
                                        deviceId={mediaspot.id}
                                        interfaces={mediaspot.wifiInterfaces}
                                        onInterfaceUpdate={onWifiInterfaceUpdate}
                                    />
                                </div>: undefined}
                        </div> */}
                        

                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setStatusMonitorExpanded(!statusMonitorExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-list-ul fa-fw`}/> Status Monitor</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {statusMonitorExpanded ?
                                <div className={classes.GroupContent}>
                                    <MediaspotStatusMonitorComponent
                                        mediaspotStatusMonitor={mediaspot.mediaspotStatusMonitor}
                                    />
                                </div>: undefined}
                        </div>


                        
                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setWelcomepageExpanded(!welcomepageExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-globe fa-fw`}/> Welcomepage</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {welcomepageExpanded ?
                                <div className={classes.GroupContent}>
                                    <WelcomePage
                                        addToast={() => {}}
                                        welcomePageTreeContents={mediaspot.welcomepageFolderContent}
                                        deviceId={mediaspot.id}
                                        />
                                </div>
                                : null}
                        </div>

                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setFirewallExpanded(!firewallExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-shield-alt fa-fw`}/> Firewall</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {firewallExpanded ?
                                <div className={classes.GroupContent}>
                                    <Firewall
                                        addToast={() => {}}
                                        firewallInformation={mediaspot.firewallInformation}
                                        deviceId={mediaspot.id}
                                        onFirewallUpdate={onFirewallInfoUpdate}
                                    />
                                </div>: undefined}
                        </div>

                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setAdvancedParametersExpanded(!advancedParametersExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-cogs fa-fw`}/> Paramètres avancés</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {advancedParametersExpanded ?
                                <div className={classes.GroupContent}>
                                    <AdvancedMediaspotParameter
                                        addToast={() => {}}
                                        deviceId={mediaspot.id}
                                        mediaspotName={mediaspot.name}
                                        mediacenterHost={mediaspot.mediacenterHost}
                                        tr069Host={mediaspot.tr069Host}
                                        firmwareUpgradeUrl={mediaspot.firmwareUpgradeUrl}
                                        syncserverWebsocketHost={mediaspot.syncserverWebsocketHost}
                                    />
                                </div>: undefined}
                        </div>


                        <div className={classes.GroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                            <label onClick={() => setCustomJSONExpanded(!customJSONExpanded)} style={{cursor: "pointer"}}><i className={`fa fa-code fa-fw`}/> Custom data</label>
                            <div className={classes.GroupTitleSeparator}/>
                            {customJSONExpanded ?
                                <div className={classes.GroupContent}>
                                    <CustomJSONComponent
                                        addToast={() => {}}
                                        customJSON={mediaspot.customJSON}
                                        deviceId={mediaspot.id}
                                        onCustomJSONUpdated={() => {}}
                                    />
                                </div>: undefined}
                        </div>
                    </>
                
                : <label>Chargement en cours...</label>
                
            }
        </>
    )
}

export default MediaspotManagement