import { useState } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { Graph, GraphType } from "../../graph/Graph";
import { Interval } from "../../navigation/tabs/Tabs";
import { Area } from "../../navigation/tree/Area";
import classes from "../Statistics.module.css"

interface IProps {
    area: Area
    nodePath: string,
    startDateTimestamp: number,
    endDateTimestamp: number,
    interval: Interval
}

export const StatsPortal = (props: IProps) => {
    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState<Layouts>({})

    return (
        <ResponsiveReactGridLayout
                rowHeight={100}
                cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                layouts={layouts}
                onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                    // Check to prevent infinite refresh
                    if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                        setLayouts(allLayouts)
                    }
                }
            }>

                <div className={classes.ChartContainer} key={"stat1"} data-grid={{ w: 6, h: 3, x: 0, y: 0, static: true }}>
                        <label>Évolution du nombre de sessions</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>            
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.SESSIONS_COUNT_EVOLUTION}
                                startDatePeriodTimestamp={props.startDateTimestamp}
                                interval={props.interval}
                                nodePath={props.nodePath}
                                mediaspotSerial={props.area.mediaspot?.serial}
                                endDatePeriodTimestamp={props.endDateTimestamp}/>
                        </div>
                </div>

                <div className={classes.ChartContainer} key={"stat2"} data-grid={{ w: 6, h: 3, x: 6, y: 0, static: true }}>
                        <label>Évolution de l'utilisation des services</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>            
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.SERVICES_USAGE_EVOLUTION}
                                startDatePeriodTimestamp={props.startDateTimestamp}
                                interval={props.interval}
                                nodePath={props.nodePath}
                                mediaspotSerial={props.area.mediaspot?.serial}
                                endDatePeriodTimestamp={props.endDateTimestamp}/>
                        </div>
                </div>

                <div className={classes.ChartContainer} key={"stat3"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                        <label>Utilisation des services</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>            
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.SERVICES_USAGE}
                                startDatePeriodTimestamp={props.startDateTimestamp}
                                interval={props.interval}
                                nodePath={props.nodePath}
                                mediaspotSerial={props.area.mediaspot?.serial}
                                endDatePeriodTimestamp={props.endDateTimestamp}/>
                        </div>
                </div>

            </ResponsiveReactGridLayout>
        
    )
}