import React, {ChangeEvent, Component, FormEvent} from "react";
import classes from "./Provisionning.module.css"
import {Trans} from "react-i18next"
import { addMediaspot, editMediaspot } from "../../api/ProvisionningRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import { Mediaspot } from "../../models/Mediaspot";
import { ApiDataResponse } from "../../api/Request";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


interface IState {
    mediaspot: Mediaspot,
    creationRequestInProgress: boolean,
}

interface IProps {
    device?: Mediaspot | undefined
}

class MediaspotsProvisionning extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            creationRequestInProgress: false,
            mediaspot: props.device !== undefined ? props.device : {
                lastWSContact: "",
                brand: "Jetway",
                serial: "",
                model: "HBJC311U93-2930",
                type: "N93 Series",
                HDDinGB: 120,
                buildVersion: "build-nspINTEGRATED-COTENTIN-2021-05-17.179",
                log_type: "REALTIME",
                gsi1sk: "26C7042F-BCE8-4C6D-9CF8-CAA2A5F6EB92|7A44E7EE-6AE3-4D70-9239-B50E7CA4B756|de8beba4-e112-4ae3-9836-6125b7680384",
                memoryInGB: 4,
                isDeployed: true,
                lastIndex: 0,
                pk: "",
                reference: "",
                searchRef: "",
                sk: "",
                status: "IN_STOCK",
                statusUpdatedOn: "",
                device_type: "mediaspot",
              }
        }
    }

    onCreateClick = async(event: FormEvent) => {
        event.preventDefault()

        await this.setState({creationRequestInProgress: true})
        let response: ApiDataResponse<object>|undefined = undefined
        if(this.props.device){
            response = await editMediaspot(this.state.mediaspot)
        }else {
            response = await addMediaspot(this.state.mediaspot)
        }

        if(response.error){
            toast(this.props.device ? "Erreur lors de l'édition du mediaspot" : "Erreur lors de la création du mediaspot", {
                type: "error"
            })
        }else {
            toast("Mediaspot ajouté au référentiel", {
                type: "success"
            })

            const deviceCopy = {...this.state.mediaspot}
            deviceCopy.serial = ""
            await this.setState({mediaspot: deviceCopy})
        }

        await this.setState({creationRequestInProgress: false})
        if(response.error){
            alert(this.props.device ? "Error during mediaspot edition" : "Error during mediaspot creation")
        }
        
    }

    onSerialChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.serial = value
        if(this.props.device === undefined){
            mediaspotCopy.reference = `mediaspot-${value}`
            mediaspotCopy.searchRef = `mediaspot-${value}`
        }
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    

    onBrandChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.brand = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onModelChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.model = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onBuildVersionChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.buildVersion = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onPathChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.gsi1sk = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onTypeChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.type = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onHDDinGBChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.HDDinGB = Number.parseInt(value)
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onMemoryinGBChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.memoryInGB = Number.parseInt(value)
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    
    onLogtypeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.log_type = value as "OFFLINE" | "REALTIME"
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.reference = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }
    
    onSearchReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.searchRef = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    render(){
        return (
            <div className={classes.ProvisionningContainer}>
                <h4>{this.props.device === undefined ? "Ajout d'un mediaspot au référentiel" : "Édition d'un mediaspot"}</h4>
                <form onSubmit={this.onCreateClick}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table className={classes.ProvisionningFieldsTable}>
                            <tr>
                                <td><label><Trans>Serial</Trans> : </label></td>
                                <td><input required onChange={this.onSerialChanged} value={this.state.mediaspot.serial} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Brand</Trans> : </label></td>
                                <td><input required onChange={this.onBrandChanged} value={this.state.mediaspot.brand} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Model</Trans> : </label></td>
                                <td><input required onChange={this.onModelChanged} value={this.state.mediaspot.model} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Build version</Trans> : </label></td>
                                <td><input required onChange={this.onBuildVersionChanged} value={this.state.mediaspot.buildVersion} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type</Trans> : </label></td>
                                <td><input required onChange={this.onTypeChanged} value={this.state.mediaspot.type} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Memory in GB</Trans> : </label></td>
                                <td><input required onChange={this.onMemoryinGBChanged} value={this.state.mediaspot.memoryInGB} type="number"/></td>
                            </tr>
                            <tr>
                                <td><label><Trans>HDD in GB</Trans> : </label></td>
                                <td><input required onChange={this.onHDDinGBChanged} value={this.state.mediaspot.HDDinGB} type="number"/></td>
                            </tr>
                            

                            <tr>
                                <td><label><Trans>LogType</Trans> : </label></td>
                                <td><select onChange={this.onLogtypeChanged}>
                                    <option key="realtime" value="REALTIME">Realtime</option>
                                    <option key="offline" value="OFFLINE">Offline</option>
                                </select>
                                </td>
                            </tr>

                            {
                                this.props.device ? 
                                <>
                                    <tr>
                                        <td><label><Trans>Reference</Trans> : </label></td>
                                        <td><input required onChange={this.onReferenceChanged} value={this.state.mediaspot.reference} type="text"/></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><label><Trans>Search reference</Trans> : </label></td>
                                        <td><input required onChange={this.onSearchReferenceChanged} value={this.state.mediaspot.searchRef} type="text"/></td>
                                    </tr>
                                </>
                                : null
                            }

                            <tr>
                                <td><label><Trans>Path {this.props.device ? "" : "(réserve)"}</Trans> : </label></td>
                                <td><input required onChange={this.onPathChanged} value={this.state.mediaspot.gsi1sk} type="text"/></td>
                            </tr>
                        </table>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.creationRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={this.props.device ? classes.ProvisionningEditButton : classes.ProvisionningValidateButton} type={"submit"}><FontAwesomeIcon icon={this.props.device ? faPen: faPlus}/> <Trans>{this.props.device ? "Edit" : "Create"}</Trans></button>}

                    </div>
                </form>
                <ToastContainer />
            </div>
        )
    }
}

export default MediaspotsProvisionning