import i18n from "../../i18n"

export const getReadableTaskDescription = (task:string, value:string) => {
    switch (true){
        case task.endsWith(".DownloadEnabled"): return i18n.t(value.toString() === "true" ? "DownloadEnabledUpdated" : "DownloadDisabledUpdated")
        case task.endsWith(".SyncNow"): return i18n.t("SyncNowUpdated")
        case task.endsWith(".DownloadAuthTokenSecret"): return i18n.t("DownloadAuthTokenSecretUpdated")
        case task.endsWith(".MakeAnalyticsNow"): return i18n.t("MakeAnalyticsNowUpdated")
        case task.endsWith(".ClientRemoveByName"): return i18n.t("ClientRemoveByNameUpdated")
        case task.endsWith(".Band"): return i18n.t("BandUpdated")
        case task.endsWith(".Channel"): return i18n.t("ChannelUpdated")
        case task.endsWith(".Ssid"): return i18n.t("SsidUpdated")
        case task.endsWith(".TxPower"): return i18n.t("TxPowerUpdated")
        case task.endsWith(".RadioEnabled"): return i18n.t(value.toString() === "true" ? "RadioEnabledUpdated" : "RadioDisabledUpdated")
        case task.endsWith(".WpaPassword"): return i18n.t("WpaPasswordUpdated")
        case task.endsWith(".BssUtilization"): return i18n.t("BssUtilizationUpdated")
        case task.endsWith(".ObssUtilization"): return i18n.t("ObssUtilizationUpdated")
        case task.endsWith(".Idle"): return i18n.t("IdleUpdated")
        case task.endsWith(".AllowedPortsUdp"): return i18n.t("AllowedPortsUdpUpdated")
        case task.endsWith(".AllowedPortsTcp"): return i18n.t("AllowedPortsTcpUpdated")
        case task.endsWith(".InternetBlockingEnabled"): return i18n.t(value.toString() === "true" ? "InternetBlockingEnabledUpdated" : "InternetBlockingDisabledUpdated")
        case task.endsWith(".AutoCaptivePortalEnabled"): return i18n.t(value.toString() === "true" ? "AutoCaptivePortalEnabledUpdated" : "AutoCaptivePortalDisabledUpdated")
        case task.endsWith(".InternetWhitelist"): return i18n.t("InternetWhitelistUpdated")
        case task.endsWith(".WelcomepageZipUrl"): return i18n.t("WelcomepageZipUrlUpdated")
        case task.endsWith(".MediaspotName"): return i18n.t("MediaspotNameUpdated")
        case task.endsWith(".MediacenterHost"): return i18n.t("MediacenterHostUpdated")
        case task.endsWith(".Tr069Host"): return i18n.t("Tr069HostUpdated")
        case task.endsWith(".WebsocketHost"): return i18n.t("WebsocketHostUpdated")
        case task.endsWith(".FirmwareUpdateUrl"): return i18n.t("FirmwareUpdateUrlUpdated")
        case task.endsWith(".MediaspotCustomInfo"): return i18n.t("MediaspotCustomInfoUpdated")
        default: return task
    }
}

export const getReadablePendingTaskDescription = (task:string, value:string) => {
    switch (true){
        case task.endsWith(".DownloadEnabled"): return i18n.t(value.toString() === "true" ? "DownloadEnabledRequest" : "DownloadDisabledRequest")
        case task.endsWith(".SyncNow"): return i18n.t("SyncNowRequest")
        case task.endsWith(".DownloadAuthTokenSecret"): return i18n.t("DownloadAuthTokenSecretRequest")
        case task.endsWith(".MakeAnalyticsNow"): return i18n.t("MakeAnalyticsNowRequest")
        case task.endsWith(".ClientRemoveByName"): return i18n.t("ClientRemoveByNameRequest")
        case task.endsWith(".Band"): return i18n.t("BandRequest")
        case task.endsWith(".Channel"): return i18n.t("ChannelRequest")
        case task.endsWith(".Ssid"): return i18n.t("SsidRequest")
        case task.endsWith(".TxPower"): return i18n.t("TxPowerRequest")
        case task.endsWith(".RadioEnabled"): return i18n.t(value.toString() === "true" ? "RadioEnabledRequest" : "RadioDisabledRequest")
        case task.endsWith(".WpaPassword"): return i18n.t("WpaPasswordRequest")
        case task.endsWith(".BssUtilization"): return i18n.t("BssUtilizationRequest")
        case task.endsWith(".ObssUtilization"): return i18n.t("ObssUtilizationRequest")
        case task.endsWith(".Idle"): return i18n.t("IdleRequest")
        case task.endsWith(".AllowedPortsUdp"): return i18n.t("AllowedPortsUdpRequest")
        case task.endsWith(".AllowedPortsTcp"): return i18n.t("AllowedPortsTcpRequest")
        case task.endsWith(".InternetBlockingEnabled"): return i18n.t(value.toString() === "true" ? "InternetBlockingEnabledRequest" : "InternetBlockingDisabledRequest")
        case task.endsWith(".AutoCaptivePortalEnabled"): return i18n.t(value.toString() === "true" ? "AutoCaptivePortalEnabledRequest" : "AutoCaptivePortalDisabledRequest")
        case task.endsWith(".InternetWhitelist"): return i18n.t("InternetWhitelistRequest")
        case task.endsWith(".WelcomepageZipUrl"): return i18n.t("WelcomepageZipUrlRequest")
        case task.endsWith(".MediaspotName"): return i18n.t("MediaspotNameRequest")
        case task.endsWith(".MediacenterHost"): return i18n.t("MediacenterHostRequest")
        case task.endsWith(".Tr069Host"): return i18n.t("Tr069HostRequest")
        case task.endsWith(".WebsocketHost"): return i18n.t("WebsocketHostRequest")
        case task.endsWith(".FirmwareUpdateUrl"): return i18n.t("FirmwareUpdateUrlRequest")
        case task.endsWith(".MediaspotCustomInfo"): return i18n.t("MediaspotCustomInfoRequest")
        default: return task
    }
}