import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { Interval } from "../../navigation/tabs/Tabs";
import { Area } from "../../navigation/tree/Area";
import classes from "../Statistics.module.css"
import {MediasRanking} from "./mediasranking/MediasRanking"

interface IProps {
    area: Area
    nodePath: string,
    startDateTimestamp: number,
    endDateTimestamp: number,
    interval: Interval,
}

export const StatsMedias = (props: IProps) => {
    const ResponsiveReactGridLayout = WidthProvider(Responsive);

    let layouts = {}
    return (
        <ResponsiveReactGridLayout
                rowHeight={100}
                cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                layouts={layouts}
                onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                    // Check to prevent infinite refresh
                    if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                        console.log("will set layout to ", allLayouts, "value was ", layouts)
                        layouts = allLayouts
                    }
                }
            }>
            
            <div className={classes.ChartContainer} key={"stat2"} data-grid={{ w: 6, h: 3, x: 0, y: 0, static: true }}>
                    <label>Classement des médias</label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>
                        <div className={classes.StatisticsGroupContent}>
                            <MediasRanking {...props}/>
                        </div>
                    </div>
            </div>

        </ResponsiveReactGridLayout>
    )
}