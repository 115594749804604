import { Mediaspot } from "../../models/Mediaspot";
import classes from "../Statistics.module.css"
import {Trans} from "react-i18next"

interface IProps {
    mediaspot: Mediaspot
}

export const MediaspotsInfo = (props: IProps) => {

    return (
        <div className={classes.StatsGroupContent}>     
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Serial</Trans></label>
                <label>{props.mediaspot.serial}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Memory</Trans></label>
                <label>{props.mediaspot.memoryInGB}GB</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>HDD</Trans></label>
                <label>{props.mediaspot.HDDinGB}GB</label>
            </div>
            {/* <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Brand</Trans></label>
                <label>{props.mediaspot.brand}</label>
            </div> */}
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Type</Trans></label>
                <label>{props.mediaspot.type}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Model</Trans></label>
                <label>{props.mediaspot.model}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Build</Trans></label>
                <label>{props.mediaspot.buildVersion}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Log type</Trans></label>
                <label>{props.mediaspot.log_type}</label>
            </div>
        </div>
    )
}