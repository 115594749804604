const startDateStorageKey = "start_date"
const endDateStorageKey = "end_date"

export const saveRangeDate = (startTimestamp: number, endTimestamp: number) => {
    localStorage.setItem(startDateStorageKey, startTimestamp.toString())
    localStorage.setItem(endDateStorageKey, endTimestamp.toString())
}

export const getRangeStartDate = (): number | null => {
    const value = localStorage.getItem(startDateStorageKey)
    if(value === null){
        return null
    }
    return Number.parseInt(value)
}

export const getRangeEndDate = (): number |  null => {
    const value = localStorage.getItem(endDateStorageKey)
    if(value === null){
        return null
    }
    return Number.parseInt(value)}

export const clearRangeEndDate = () => {
    localStorage.removeItem(startDateStorageKey)
    localStorage.removeItem(endDateStorageKey)
}