import {Component} from 'react';
import {logout} from '../../api/Auth'

class Logout extends Component {
    async componentWillMount(){
        await logout()
    }

    render() {
        return null
    }
}

export default Logout;
