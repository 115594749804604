// ------------------
// ---- Requests ----
// ------------------

import { getIntervalString, Interval } from "../navigation/tabs/Tabs"
import { getClientUUID } from "./Auth"
import { ApiDataResponse, post } from "./Request"

export const getSessionsAmountEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<SessionAmountEvolutionResponse>> => {
    const response = await post<SessionAmountEvolutionResponse>("getsessionscountevolution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        interval: getIntervalString(interval),
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
    return response
}

export const getServicesUsageAmountEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<ServiceUsageAmountEvolutionResponse>> => {
    const response = await post<ServiceUsageAmountEvolutionResponse>("getservicesusageevolution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        interval: getIntervalString(interval),
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
    return response
}

export const getServicesUsage = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<ServiceUsageAmountResponse>> => {
    const response = await post<ServiceUsageAmountResponse>("getservicesusage", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
    return response
}



// -------------------
// ---- Types def ----
// -------------------

/***
 * Evolution of session number on time period
 */
export type SessionAmountEvolutionResponse = Array<{
    date: string,
    count: number
}>

/***
 * Evolution of services usage on time period
 */
export type ServiceUsageAmountEvolutionResponse = Array<{
    date: string,
    services: Array<
        {
            service: string,
            count: number
        }
    >
}>

/***
 * Service usage 
 */
 export type ServiceUsageAmountResponse = Array<{
    service: string,
    count: number
}>

