import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner'

import classes from "./Tree.module.css"
import TreeNode from './TreeNode';


import {getOrganization, getOrgaTypes} from "../../api/OrganizationRequests"
import {getDevices, getMediaspots, AssociatedDevicesParameter} from "../../api/DevicesRequests"

import {
    findPathOfParentKey,
    getAllFlatedNodes,
    getAllStoreNodes,
    getChildKeys,
    getVarPath
} from "../../helpers/factories/FactoriesHelper"
import * as portalConfig from "../../portal_config.json"
import {NodeType} from "./NodeType"
//import DissociationModal from "../../admin/admin_areas/DissociationModal";
import {Trans} from "react-i18next";
import {getClientUUID} from "../../api/Auth"
import {getWebsocketServerLastContacts} from "../../api/MediaspotRequests"
import DissociationModal from './dissociationmodal/DissociationModal';


export default class Tree extends Component {


    state = {
        orgaTypeUUID: undefined,
        types: undefined,

        factories: undefined,
        factoriesLoading: true,

        loadingError: undefined,

        storeNodes:[],

        // When a new node is adding via form, this field contains parent node where new node will be added
        newNodeParentId: undefined,

        editingNodeId: undefined,


        allDevices: [],
        dissociationNode: undefined,
        dissociationDevicePath: undefined,
        dissociationInProgress: false,

        deviceToBlink: undefined

    }

    static getTypeFromString = (level, label) => {
        if(level === undefined || label === undefined){
            return undefined
        }

        if(label === level.nodeType){
            return level
        }
        if(level.child !== undefined){
            return this.getTypeFromString(level.child, label)
        }
        return undefined;
    }

    async componentDidMount() {

        if(this.props.isAreaManagement === undefined || this.props.isAreaManagement === false){
            setInterval(this.reloadConfig, portalConfig.SUPERVISION_REFRESH_TICK_MILLISECONDS)
        }

        const responses = await Promise.all([
            getOrganization(),
            getDevices(undefined, undefined, AssociatedDevicesParameter.ALL,[NodeType.MEDIASPOT, NodeType.ROUTER, NodeType.SIM], getClientUUID()),
            getWebsocketServerLastContacts()
        ])


        const factoriesResponse = responses[0]
        if(factoriesResponse.error !== undefined){
            this.setState({
                factoriesLoading: false,
                loadingError: factoriesResponse.error
            })
            return
        }

        factoriesResponse[Object.keys(factoriesResponse)[0]]["isOpen"] = true
        let currentNode = factoriesResponse[Object.keys(factoriesResponse)[0]]
        let currentPath = Object.keys(factoriesResponse)[0]
        let currentNodeId = Object.keys(factoriesResponse)[0]
        // If root node is not allowed, searching from all child node first allowed node
        if(factoriesResponse[Object.keys(factoriesResponse)[0]]["isAllowed"] === false){

            while(currentNode !== undefined && currentNode["isAllowed"] === false){
                currentNodeId = getChildKeys(currentNode)[0]
                currentPath += ("|" + currentNodeId)

                currentNode = currentNode[currentNodeId]
                currentNode["isOpen"] = true
            }

        }

        const orgTypeUUID = factoriesResponse[Object.keys(factoriesResponse)[0]]["orgTypeUUID"]
        const typesResponse = await getOrgaTypes(orgTypeUUID)

        if(typesResponse.error !== undefined){
            this.setState({
                factories: undefined, // In case of error, store factory now to don't reload it later, else store when all is loaded
                loadingError: typesResponse.error
            })
            return
        }

        if(this.props.autoExpandAllAreas === true){
            this.openChilds(factoriesResponse[Object.keys(factoriesResponse)[0]])
        }

        const storeNodes = getAllStoreNodes(factoriesResponse[Object.keys(factoriesResponse)[0]], Object.keys(factoriesResponse)[0])

        const devicesResponse = responses[1]
        if(devicesResponse.error !== undefined){
            this.setState({
                factories: factoriesResponse, // In case of error, store factory now to don't reload it later, else store when all is loaded
                loadingError: devicesResponse.error
            })
            return;
        }


        let mediaspotsResponse = devicesResponse["mediaspot"]["Items"]
        let routersResponse = devicesResponse["router"]["Items"]
        let simsResponse = devicesResponse["sim"]["Items"]
        

        const mediaspotsLastContactResponse = responses[2]
        if(mediaspotsLastContactResponse.error === undefined){
            mediaspotsLastContactResponse.forEach(wsMediaspotContactInfo => {
                try {
                    const wsMediaspotSerial = wsMediaspotContactInfo.mediaspot_id.split("-")[2].toLowerCase() // FFFFFF-Mediaspot-GEWY47583822 => GEWY47583822
                    const mediaspot = mediaspotsResponse.find(ms => {
                        const mediaspotSerial = ms.pk.split("|")[0].toLowerCase().split("-")[1]
                        return wsMediaspotSerial === mediaspotSerial
                    })
                    if(mediaspot !== undefined){
                        mediaspot.lastWSContact = wsMediaspotContactInfo.lastinform
                    }
                }catch(error){}
            })
        }

        const allDevices = [...routersResponse, ...mediaspotsResponse, ...simsResponse]


        if(this.props.isAreaManagement === false){
            for(let i = 0 ; i < routersResponse.length ; i++){
                let routerPath = routersResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, routerPath)

                if(originalObject["router"] === undefined){
                    originalObject["router"] = [];
                }

                let existingRouterInTree = originalObject["router"].find(it => it.macaddr === routersResponse[i].macaddr)
                if(existingRouterInTree !== undefined){
                    existingRouterInTree = routersResponse[i]
                }else {
                    originalObject["router"].push(routersResponse[i])
                }
            }
            for(let i = 0 ; i < mediaspotsResponse.length ; i++){
                let routerPath = mediaspotsResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, routerPath)
                originalObject["mediaspot"] = mediaspotsResponse[i]
            }
        }else {
            // If area management
            // Loop over all mediaspot from response add add them to current tree
            for(let i = 0 ; i < mediaspotsResponse.length ; i++){
                let routerPath = mediaspotsResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, routerPath)
                if(originalObject.isAllowed === true){
                    originalObject["mediaspot"] = mediaspotsResponse[i]
                    const mediaspotKey = mediaspotsResponse[i].pk.replace("|", "_@_")

                    originalObject[mediaspotKey] = {
                        type: NodeType.MEDIASPOT,
                        val: mediaspotsResponse[i].reference,
                        id: mediaspotsResponse[i].pk,
                        nodePath: mediaspotsResponse[i].gsi1sk,
                        isArchiveDevice: mediaspotsResponse[i].status === "ARCHIVE"
                    }
                }
            }

            this.addDeviceToParent(routersResponse, NodeType.ROUTER, factoriesResponse)
            this.addDeviceToParent(simsResponse, NodeType.SIM, factoriesResponse)
        }

        this.setState({allDevices: allDevices, orgaTypeUUID: orgTypeUUID, types: typesResponse.org, factories: factoriesResponse, loadingError: undefined, storeNodes: storeNodes}, () => {
            this.onNodeSelect(currentNode, currentNodeId, currentPath)
            if(this.props.onFactoriesLoaded !== undefined){
                this.props.onFactoriesLoaded({...this.state.factories})
            }
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If new device to focus
        if(prevProps.focusedDevice !== this.props.focusedDevice && this.props.focusedDevice !== undefined){
           this.showDevice(this.props.focusedDevice.gsi1sk, this.props.focusedDevice.pk)
        }

        if(prevProps.focusedDevice !== undefined && this.props.focusedDevice === undefined){
            this.setState({deviceToBlink: undefined})
        }
    }

    addDeviceToParent = (devicesToAdd, deviceType, factories) => {
        // Loop over all modac from response
        for(let i = 0 ; i < devicesToAdd.length ; i++){
            let parentNode = undefined;
            // get path of node where modac is binded
            let nodePath = devicesToAdd[i].gsi1sk
            // get node object matching with modac node path
            let originalObject = getVarPath(factories, nodePath)

            // In case of in stock device, any device is associated, so they'll be added direcly in stock node
            if(devicesToAdd[i].status === "IN_STOCK"){
                parentNode = getVarPath(factories, nodePath)
            }else{
                // If parent device is not found is tree, continue
                if(devicesToAdd[i].parent_key === undefined){
                    continue;
                }
                const parentPath = findPathOfParentKey(originalObject, nodePath, devicesToAdd[i].parent_key.replace("|", "_@_"))
                if(parentPath === undefined){
                    continue
                }
                parentNode = getVarPath(factories, parentPath)
            }

            parentNode[devicesToAdd[i].pk.replace("|", "_@_")] = {
                type: deviceType,
                val: devicesToAdd[i].reference,
                id: devicesToAdd[i].pk,
                nodePath: devicesToAdd[i].gsi1sk,
                isArchiveDevice: devicesToAdd[i].status === "ARCHIVE",
                maxNbProbes: devicesToAdd[i].maxNbProbes
            }
        }
    }

    showDevice = (nodePath, deviceId) => {
        console.log(nodePath)
        console.log(deviceId)

        const pathComponents = nodePath.split("|")
        let currentPath = pathComponents[0]
        // Open all nodes of area path node by node
        for(let i = 1 ; i <= pathComponents.length ; i++){
            const node = getVarPath(this.state.factories, currentPath)
            node["isOpen"] = true
            currentPath += `|${pathComponents[i]}`
        }

        // Get final destination node (area)
        let originalObject = getVarPath(this.state.factories, nodePath)

        let deviceToFind = this.state.allDevices.find(device => device.pk === deviceId)

        if(deviceToFind === undefined || deviceToFind.parent_key === undefined){
            this.setState({deviceToBlink: deviceToFind})
            return
        }

        let deviceIdToFound = deviceToFind.parent_key.replace("|", "_@_")
        let parentPath = findPathOfParentKey(originalObject, nodePath, deviceIdToFound)

        let currentDevice = {...deviceToFind}
        while(parentPath !== undefined ){
            let object = getVarPath(this.state.factories, parentPath)
            object["isOpen"] = true

            currentDevice = this.state.allDevices.find(device => device.pk === object.id)
            if(currentDevice !== undefined && currentDevice.parent_key !== undefined){
                deviceIdToFound = currentDevice.parent_key.replace("|", "_@_")
                parentPath = findPathOfParentKey(originalObject, nodePath, deviceIdToFound)
            }else {
                parentPath = undefined
            }
        }
        this.setState({deviceToBlink: deviceToFind})
    }

    openChilds = (currentNode) => {
        const childKeys = getChildKeys(currentNode)
        childKeys.forEach(it => {
            if(currentNode[it].hasMediaspot !== true){
                currentNode[it]["isOpen"] = true
                this.openChilds(currentNode[it])

            }
        })
    }

    reloadConfig = async() => {
        const response = await Promise.all([
            this.loadMediaspots(),
            // this.loadModacs()
        ]);
        this.forceUpdate()

        if(this.state.loadingError !== undefined && response[0] !== undefined && response[0].error === undefined && response[1].error === undefined){
            this.setState({loadingError: undefined})
        }
    }

    loadMediaspots = async() => {
        const responses = await Promise.all([
            getMediaspots(),
            // getWebsocketServerLastContacts()
        ])
        const mediaspotsResponse = responses[0]
        // const mediaspotsLastContactResponse = responses[1]
        if(mediaspotsResponse.error !== undefined){
            return {
                error: mediaspotsResponse.error
            }
        }

        for(let i = 0 ; i < mediaspotsResponse.length ; i++){
            let path = mediaspotsResponse[i].gsi1sk
            const originalObject = getVarPath(this.state.factories, path)

            if(originalObject["mediaspot"] !== undefined){

                // if(mediaspotsLastContactResponse.error === undefined){
                //     const lastContactInfos= mediaspotsLastContactResponse.find(it => {
                //         const wsMediaspotSerial =  it.mediaspot_id.split("-")[2].toLowerCase()
                //         const mediaspotSerial = mediaspotsResponse[i].pk.split("|")[0].split("-")[1].toLowerCase()
                //         return mediaspotSerial === wsMediaspotSerial
                //     })
                //     if(lastContactInfos !== undefined){
                //         mediaspotsResponse[i].lastWSContact = lastContactInfos.lastinform
                //     }else {
                //         mediaspotsResponse[i].lastWSContact = undefined
                //     }
                // }

                Object.assign(originalObject["mediaspot"], mediaspotsResponse[i])
            }else if(originalObject !== ''){
                originalObject["mediaspot"] = mediaspotsResponse[i]
            }
        }
    }

    getChildNodes = (node) => {
        let nodes = getChildKeys(node).map(nodeKey => {
            return {id: nodeKey, node: node[nodeKey]}
        })

        // If node are not already created, it'll be added dynamilly here
        if(this.props.isAreaManagement === false){
            if(node.mediaspot !== undefined && (this.props.isAreaManagement === true)){
                nodes.push({
                    id: node.mediaspot.pk,
                    node:{
                        type: "mediaspot",
                        val: node.mediaspot.serial,
                    }
                })

            }

            // if(node.modacs !== undefined && node.modacs.length > 0){
            //     node.modacs.forEach(modacInfo => {

            //         nodes.push({
            //             id: modacInfo.pk,
            //             node: {
            //                 type: "modac",
            //                 val: modacInfo.reference,
            //                 ...modacInfo

            //             }
            //         })
            //     })
            // }
        }

        //Remove node of type reserve if preventState
        if(this.props.isAreaManagement !== true){
            nodes = nodes.filter(it => {
                if(it.node !== undefined){
                    return it.node.isStore !== true
                }
                return true
            })
        }

        return nodes.sort((nodeA, nodeB) => {
            if(nodeA.node.type === NodeType.MEDIASPOT && nodeB.node.type !== NodeType.MEDIASPOT){ return -1 }
            if(nodeB.node.type === NodeType.MEDIASPOT && nodeA.node.type !== NodeType.MEDIASPOT){ return 1 }
            if(nodeA.node.isStore === true && nodeB.node.isStore !== true){ return -1 }
            if(nodeB.node.isStore === true && nodeA.node.isStore !== true){ return 1 }
            if(nodeB.node.isStore === true && nodeA.node.isStore !== true){ return 1 }

            // if(nodeA.node.type === NodeType.MODAC && nodeB.node.type === NodeType.MODAC && this.isGoodMediaspotConfigured(nodeA.node) === false){return -1}
            // if(nodeA.node.type === NodeType.MODAC && nodeB.node.type === NodeType.MODAC && this.isGoodMediaspotConfigured(nodeA.node) === true){return 1}

            return nodeA.node.val > nodeB.node.val ? 1 : -1
        })
    }

    isGoodMediaspotConfigured = (node) => {
        if(node.detectedMediaspotSerial !== undefined && node.parent_key !== undefined && node.parent_key.includes("mediaspot-")){
            const extractedSerial = node.parent_key.replace("mediaspot-", "").split("|")[0]
            if(extractedSerial !== node.detectedMediaspotSerial){
                return false
            }
        }
        return true
    }

    onToggle = (node) => {
        if(node["isOpen"] === undefined){
            node["isOpen"] = true
        }else {
            node["isOpen"] = !node["isOpen"]
        }
    }

    onNodeSelect = (node, nodeId, nodePath) => {
        const { onSelect } = this.props;
        onSelect(node, nodeId, nodePath);
    }

    onNewNodeCreated = (id, name, levelType, path, isStore) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject[id] = {
            hasMediaspot: false,
            isAllowed: true,
            isOpen: false,
            type: levelType.nodeType,
            val: name,
            isStore: isStore
        }

        this.setState({newNodeParentId: undefined})
    }

    onNodeAddingCancelled = () => {
        this.setState({newNodeParentId: undefined})
    }

    onNodeEditionCancelled = () => {
        this.setState({editingNodeId: undefined})
    }

    onDeleteNode = (nodeId, path) => {
        const parentPathComponents = path.split('|');
        if(parentPathComponents.length > 1){
            const toRemoved = parentPathComponents.pop()
            if(toRemoved === nodeId){
                const parentPath = parentPathComponents.reduce((it, val) => it + "|" + val)
                const originalObject = getVarPath(this.state.factories, parentPath)
                delete originalObject[nodeId]

                this.forceUpdate()
            }
        }
    }

    onDeleteDevice = (deviceId, path) =>{
        const parentPathComponents = path.split('|');
        if(parentPathComponents.length > 1){
            const toRemovedDeviceId = parentPathComponents.pop()
            if(toRemovedDeviceId === deviceId){
                const parentPath = parentPathComponents.reduce((it, val) => it + "|" + val)
                const originalObject = getVarPath(this.state.factories, parentPath)
                delete originalObject[deviceId]

                this.forceUpdate()
            }
        }
    }


    onNodeEdited = (path, label, type) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject["val"] = label
        originalObject["type"] = type.nodeType

        this.setState({editingNodeId: undefined})
    }

    onDeviceDropped = (path, fullObject, nodeType, nodePath) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject["isOpen"] = true
        if(nodeType === NodeType.MEDIASPOT){
            originalObject["hasMediaspot"] = true
        }
        const key = fullObject.pk.replace("|", "_@_")
        originalObject[key] = {
            type: nodeType,
            val: fullObject.reference,
            id: fullObject.pk,
            nodePath: nodePath,
            maxNbProbes: fullObject.maxNbProbes
        }

        const storeNodePath = fullObject.gsi1sk
        const storeNodeOriginalObject = getVarPath(this.state.factories, storeNodePath)

        if(storeNodeOriginalObject !== undefined){
            delete storeNodeOriginalObject[key]
        }
        this.forceUpdate()
    }

    onDissociateRequest = (node, path) => {
        this.setState({dissociationInProgress: true, dissociationNode: node, dissociationDevicePath: path })
    }

    onDissociationModalClosed = () => {
        this.setState({dissociationInProgress: false, dissociationNode: undefined, dissociationDevicePath: undefined })
    }

    onDissociationSuccess = (storeNodeDestinationPath) => {
        console.log("onDissociationSuccess")
        console.log(this.state.allDevices)
        console.log(storeNodeDestinationPath)
        

        let nodePathComponents = this.state.dissociationDevicePath.split("|")
        const nodeIdToDelete = nodePathComponents.pop()
        let parentNodePath = nodePathComponents.reduce((it1, it2) => `${it1}|${it2}`)

        const originalObject = getVarPath(this.state.factories, parentNodePath)

        console.log(originalObject)


        // If deleted object is a mediaspot, parent node "hasMediaspot" attribute has to be reset to false
        const objectToDelete = getVarPath(this.state.factories, this.state.dissociationDevicePath)
        console.log(objectToDelete)

        if(objectToDelete.type === NodeType.MEDIASPOT){
            originalObject["hasMediaspot"] = false
        }

        // Make a copy of object to delete
        const copyOfDeletedObject = {...originalObject[nodeIdToDelete]}
        delete originalObject[nodeIdToDelete]

        // Get store node destination object
        const storeNodeDestination = getVarPath(this.state.factories, storeNodeDestinationPath)

        // Get all nodes and (sub-nodes) of deleted node to notify ids after path edition
        let flatNodes = getAllFlatedNodes(copyOfDeletedObject, true)

        // Get all keys child to keep other keys
        //storeNodeDestination[nodeIdToDelete] = copyOfDeletedObject
        flatNodes.forEach(node => {
            node.nodePath = storeNodeDestinationPath
            storeNodeDestination[node.id.replace("|", "_@_")] = node
        })
        getChildKeys(copyOfDeletedObject).forEach(keyToDelete => delete copyOfDeletedObject[keyToDelete])

        let originalNodeToDelete = {...copyOfDeletedObject}
        const keysToDelete = getChildKeys(originalNodeToDelete)
        keysToDelete.forEach(keyToDelete => {
            delete originalNodeToDelete[keyToDelete]
        })

        console.log(flatNodes)

        let devicesToDissociated = flatNodes.map(node => {
            console.log(node)
            return this.state.allDevices.find(it => node.id === it.pk && it.status !== "ARCHIVE")
        }).map(devices => {
            // After device dissociation, its original object has to be updated (status, path with store path and its deployment status)
            devices.gsi1sk = storeNodeDestinationPath
            devices.status = "IN_STOCK"
            devices.isDeployed = false
            return devices
        })

        this.props.onDevicesDissociated(devicesToDissociated)

        this.setState({dissociationInProgress: false, dissociationNode: undefined, dissociationDevicePath: undefined})
    }


    render() {
        if(this.state.factories === undefined && this.state.loadingError === undefined){
            return <div className={classes.TreeLoadingContainer}>
                <Loader type="Oval" color="#24A6E1" height={50} width={50}/>
            </div>
        }

        if(this.state.loadingError !== undefined){
            return <div>
                <label id={"tree_loading_error_label"}><Trans>AnErrorOccurredPleaseReload</Trans></label>
            </div>
        }
        console.log(this.state.factories)

        return Object.keys(this.state.factories).map(nodeKey => {
            return {
                id: nodeKey,
                val: this.state.factories[nodeKey]
            }
        }).map(node => {
            return (
                <div key={node.id}>
                    <TreeNode
                        key={node.id}
                        alreadySelectedNodeId={this.props.alreadySelectedNodeId}
                        onSelectModac={this.props.onSelectModac}
                        levelIndex={0}
                        levelType={NodeType.AREA}
                        node={node.val}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNodeSelect={this.onNodeSelect}
                        nodeId={node.id}
                        path={node.id}
                        isAreaManagement={this.props.isAreaManagement}
                        enableEdition={this.props.enableEdition}
                        onAddClick={(parentNodeId) => { this.setState({newNodeParentId: parentNodeId})}}
                        onEditClick={(nodeId) => {this.setState({editingNodeId: nodeId})}}
                        newNodeAdding={this.state.newNodeParentId}
                        editingNodeId={this.state.editingNodeId}
                        isEditing={this.state.editingNodeId !== undefined && node.id === this.state.editingNodeId}

                        onNewNodeCreated={this.onNewNodeCreated}
                        onNodeAddingCancelled={this.onNodeAddingCancelled}
                        onNodeEdited={this.onNodeEdited}
                        onNodeEditionCancelled={this.onNodeEditionCancelled}
                        onNodeDeleted={this.onDeleteNode}

                        hideVisibleNodes={this.props.hideVisibleNodes}
                        hideStoreNodes={this.props.hideStoreNodes}
                        hideArchiveDevices={this.props.hideArchiveDevices}
                        types={this.state.types}

                        onMediaspotDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.MEDIASPOT, nodePath)}
                        onModacDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.MODAC, nodePath)}
                        onRouterDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.ROUTER, nodePath)}
                        onRadiameterDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.RADIAMETER, nodePath)}
                        onProbeDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.PROBE, nodePath)}
                        onSimDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.SIM, nodePath)}

                        onDissociateDevice={this.onDissociateRequest}
                        orgTypeUUID={this.state.orgaTypeUUID}

                        deviceToBlink={this.state.deviceToBlink}

                    />

                    {this.state.dissociationInProgress === true ? <DissociationModal onDissociationSuccess={this.onDissociationSuccess} dissociationNode={this.state.dissociationNode} storeNodes={this.state.storeNodes} onDismissModal={this.onDissociationModalClosed}/> : undefined}


                </div>
            )
        })
    }
}

Tree.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onSelectModac: PropTypes.func.isRequired,
    alreadySelectedNodeId: PropTypes.any,
    maxLevel: PropTypes.any,

    onFactoriesLoaded: PropTypes.func,
    onModacFetched: PropTypes.func,

    isAreaManagement: PropTypes.bool,
    enableEdition: PropTypes.bool,

    hideVisibleNodes: PropTypes.bool,
    hideStoreNodes: PropTypes.bool,

    autoExpandAllAreas: PropTypes.bool,
    onDevicesDissociated: PropTypes.func,

    focusedDevice: PropTypes.any

};
