import { versions } from "process"
import { BrowsersRepartitionApiResponse } from "../../../api/MaintenanceRequests"
import { MaterialColors } from "../../../helpers/Colors"
import { EvolutionDataset } from "../EvolutionDataset"

export const getBrowsersRepartitionDataset = (data: BrowsersRepartitionApiResponse): EvolutionDataset => {
    const labels:Array<string> = new Array<string>()

    // console.log(data.map(it => it.versions.map(versionInfo => versionInfo.version)).flat(2))
    // console.log(data.map(it => it.versions.map(versionInfo => versionInfo.count)).flat(2))

    // console.log(data.map(it => it.browser))
    // console.log(data.map(it => it.count))

    console.log(data.map(it => it.versions.map(versionInfo => versionInfo.version)).flat(2))

    const innerDataset = {
        data: data.map(it => it.versions.map(versionInfo => versionInfo.count)).flat(2),
        backgroundColor: MaterialColors,
        labels: data.map(it => it.versions.map(versionInfo => versionInfo.version)).flat(2)
      };
      
      
        console.log(

          innerDataset
          )
    return {
        datasets: [{
            data: data.map(it => it.count),
            backgroundColor: MaterialColors,
            // labels: data.map(it => it.browser)
          },
          innerDataset
          
        ],
        dates: [],
        error: undefined,
        // labels: data.map(it => it.browser),
        labels: data.map(it => it.versions.map(versionInfo => versionInfo.version)).flat(2),
        maxValue: undefined   
    }
}
