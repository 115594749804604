import {postRequest} from "./Request"
import config from "../config"

/***
 * Get all organization nodes details
 * @returns {Promise<any|{error: *}>} promise with error key or organization nodes object
 */
export const getOrganization = async() => {
    return await postRequest(config.API_URL + "/getorga")
}

/***
 * Get all organization types used to get node type information (picto, label, level...)
 * @param orgaTypeUUID organization type unique identifier used to bind nodeType parameter to its type.
 * Retrieved from root node of getorga endpoint
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
export const getOrgaTypes = async(orgaTypeUUID: string) => {
    const payload = JSON.stringify({
        "orgtypeUUID": orgaTypeUUID
    })

    return await postRequest(config.API_URL + "/getorgtype", payload)
}

/***
 * Create a new area node on organization with specific type, specific label on a specific node
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param path node path where new node will be added as children
 * @param reserve boolean indicating if this new node
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>}
 */
 export const addNode = async(label:string, nodeType:string, path:string, reserve:boolean, orgTypeUUID:string) => {
    const body = JSON.stringify({
        label: label,
        type: nodeType,
        parentNode: path,
        store: reserve,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/addnode", body)
}


/***
 * Edit label or type of an area node
 * @param nodePath node path to edit ("xxxxxxxxxxxxx|yyyyyyyyyyyyyy|....)
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
 export const editNode = async(nodePath:string, label:string, nodeType:string, orgTypeUUID:string) => {
    const body = JSON.stringify({
        node: nodePath,
        label: label,
        type: nodeType,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/editnode", body)
}

/***
 * Delete a organization node
 * @param path node path to delete (this path should not contains child node or associated devices)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
 export const deleteNode = async(path:string, orgTypeUUID:string) => {
    const body = JSON.stringify({
        node: path,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/deletenode", body)
}