// ------------------
// ---- Requests ----
// ------------------

import { getClientUUID } from "./Auth"
import { post, ApiDataResponse } from "./Request"
import { Router } from "../models/Router"
import { Mediaspot } from "../models/Mediaspot"
import { Sim } from "../models/Sim"

/***
 * Add new router to database
 * @param router router object to add on database
 */
export const addrouter = async(router: Router): Promise<ApiDataResponse<AddRouterApiResponse>> => {
    return await post<AddRouterApiResponse>("addrouter", {
        clientUUID: getClientUUID(),
        dest_node: router.gsi1sk.split("|")[router.gsi1sk.split("|").length - 1],
        serial: router.serial,
        brand: router.brand,
        model: router.model,
        rutxFirmware: router.configSet.rutx_firmware,
        rutxMccmnc: router.configSet.rutx_mccmnc,
        log_type: router.log_type,
        reference: router.reference,
        rutxBatch: router.rutx_batch,
        status: router.status
    })
}


/***
 * Edit existing router on database
 * @param router router object to edit on database
 */
 export const editRouter = async(router: Router): Promise<ApiDataResponse<EditRouterApiResponse>> => {
    return await post<EditRouterApiResponse>("editrouter", {
        id: router.pk.replace("router-", "").replace("|0", ""),
        clientUUID: getClientUUID(),
        dest_node: router.gsi1sk.split("|")[router.gsi1sk.split("|").length - 1],
        serial: router.serial,
        brand: router.brand,
        model: router.model,
        rutxFirmware: router.configSet.rutx_firmware,
        rutxMccmnc: router.configSet.rutx_mccmnc,
        log_type: router.log_type,
        reference: router.reference,
        searchRef: router.searchRef,
        rutxBatch: router.rutx_batch,
    })
}

/***
 * Add new mediaspot to database
 * @param mediaspot mediaspot object to add on database
 */
 export const addMediaspot = async(mediaspot: Mediaspot): Promise<ApiDataResponse<AddMediaspotApiResponse>> => {
    return await post<AddMediaspotApiResponse>("addmediaspot", {
        clientUUID: getClientUUID(),
        dest_node: mediaspot.gsi1sk.split("|")[mediaspot.gsi1sk.split("|").length - 1],
        serial: mediaspot.serial,
        brand: mediaspot.brand,
        model: mediaspot.model,
        log_type: mediaspot.log_type,
        reference: mediaspot.reference,
        status: mediaspot.status,
        type: mediaspot.type,
        HDDinGB: mediaspot.HDDinGB,
        memoryInGB: mediaspot.memoryInGB,
        buildVersion: mediaspot.buildVersion
    })
}



/***
 * Add new SIM card to database
 * @param sim sim object to add on database
 */
 export const addSim = async(sim: Sim): Promise<ApiDataResponse<AddSimApiResponse>> => {
    return await post<AddSimApiResponse>("addsim", {
        clientUUID: getClientUUID(),
        dest_node: sim.gsi1sk.split("|")[sim.gsi1sk.split("|").length - 1],
        serial: sim.serial,
        brand: sim.brand,
        model: sim.model,
        log_type: sim.log_type,
        reference: sim.reference,
        billingDay: sim.billingDay,
        phonenumber: sim.phonenumber,
        pin: sim.pin,
        puk1: sim.puk1,
        puk2: sim.puk2
    })
}

/***
 * Edit existing SIM card on database
 * @param sim sim object to edit on database
 */
 export const editSim = async(sim: Sim): Promise<ApiDataResponse<EditSimApiResponse>> => {
    return await post<EditSimApiResponse>("editsim", {
        id: sim.pk.replace("sim-", "").replace("|0", ""),
        clientUUID: getClientUUID(),
        dest_node: sim.gsi1sk.split("|")[sim.gsi1sk.split("|").length - 1],
        serial: sim.serial,
        brand: sim.brand,
        model: sim.model,
        log_type: sim.log_type,
        reference: sim.reference,
        searchRef: sim.searchRef,
        billingDay: sim.billingDay,
        phonenumber: sim.phonenumber,
        pin: sim.pin,
        puk1: sim.puk1,
        puk2: sim.puk2
    })
}


/***
 * Edit existing mediaspot on database
 * @param mediaspot mediaspot object to edit on database
 */
 export const editMediaspot = async(mediaspot: Mediaspot): Promise<ApiDataResponse<EditMediaspotApiResponse>> => {
    return await post<EditMediaspotApiResponse>("editmediaspot", {
        id: mediaspot.pk.replace("mediaspot-", "").replace("|0", ""),
        clientUUID: getClientUUID(),
        dest_node: mediaspot.gsi1sk.split("|")[mediaspot.gsi1sk.split("|").length - 1],
        serial: mediaspot.serial,
        brand: mediaspot.brand,
        model: mediaspot.model,
        log_type: mediaspot.log_type,
        reference: mediaspot.reference,
        searchRef: mediaspot.searchRef,
        status: mediaspot.status,
        type: mediaspot.type,
        HDDinGB: mediaspot.HDDinGB,
        memoryInGB: mediaspot.memoryInGB,
        buildVersion: mediaspot.buildVersion
    })
}

// -------------------
// ---- Types def ----
// -------------------

export type AddRouterApiResponse = Array<{
    message: string,
}>

export type AddMediaspotApiResponse = Array<{
    message: string,
}>

export type AddSimApiResponse = Array<{
    message: string,
}>

export type EditRouterApiResponse = Array<{
    message: string,
}>

export type EditMediaspotApiResponse = Array<{
    message: string,
}>

export type EditSimApiResponse = Array<{
    message: string,
}>

