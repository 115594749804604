import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .use(LanguageDetector).init({
    resources: {
        en: {
            translations: {
                Serial: "Serial",
                Reference: "Reference",
                Yes: "Yes",
                No: "No",
                NoDataOnThisPeriod: "No data on this period",
                Confirmation: "Confirmation",
                AreYouSureYouWantToDissociate: "Are you sure you want to dissociate",
                PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Please select a stock area to put device you want to dissociate",
                Dissociate: "Dissociate",

                // Interval selector
                PerHour: "Per hour",
                PerDay: "Per day",
                d: 'd',

                // Date range picker
                SelectPeriod: "Select period",
                Last24Hours: "Last 24 hours",
                Last48Hours: "Last 48 hours",
                Last7Days: "Last 7 days",
                LastMonth: "Last month",
                LastYear: "Last year",

                AreYouSureYouWantToRemoveThisArea: "Are you sure you want to remove this area ?",
                AreYouSureYouWantToRemoveThisDevice: "Are you sure you want to remove this device ?",
                AreYouSureYouWantToRemoveThisMediaspot: "Are you sure you want to remove this mediaspot ? All modacs associated to this mediaspots will be dissociated too",

                DropThisMediaspotOnABusToBindIt: "Drop this mediaspot on a bus to bind it",
                DropThisRouterOnAMediaspotToBindIt: "Drop this router on a mediaspot to bind it",
                DropThisModacOnAMediaspotToBindTt: "Drop this modac on mediaspot to bind it",
                DropThisSimOnARouterToBindIt: "Drop this SIM card on a router to bind it",

                DownloadEnabledUpdated: "Download enabled",
                DownloadDisabledUpdated: "Download disabled",
                SyncNowUpdated: "Sync in progress ",
                DownloadAuthTokenSecretUpdated: "Download auth token secret updated",
                MakeAnalyticsNowUpdated: "Analytics started",
                ClientRemoveByNameUpdated: "Deleted client",
                BandUpdated: "Updated band",
                ChannelUpdated: "Updated channel",
                SsidUpdated: "Updated SSID",
                TxPowerUpdated: "Updated Tx power",
                RadioEnabledUpdated: "Enabled radio",
                RadioDisabledUpdated: "Disabled radio",
                WpaPasswordUpdated: "Updated WPA password",
                BssUtilizationUpdated: "Updated bss utilization",
                ObssUtilizationUpdated: "Updated obss utilization",
                IdleUpdated: "Updated idle channel",
                AllowedPortsUdpUpdated: "Updated allowed UDP ports",
                AllowedPortsTcpUpdated: "Updated allowed TCP ports",
                InternetBlockingEnabledUpdated: "Internet blocking enabled",
                InternetBlockingDisabledUpdated: "Internet blocking disabled",
                AutoCaptivePortalEnabledUpdated: "Automatic captive portal enabled",
                AutoCaptivePortalDisabledUpdated: "Automatic captive portal disabled",
                InternetWhitelistUpdated: "Updated internet whitelist",
                WelcomepageZipUrlUpdated: "Updated welcomepage zip url",
                MediaspotNameUpdated: "Mediaspot name updated",
                MediacenterHostUpdated: "Mediacenter host updated",
                Tr069HostUpdated: "TR069 host updated",
                WebsocketHostUpdated: "Websocket server host updated",
                FirmwareUpdateUrlUpdated: "Firmware update request OK",
                MediaspotCustomInfoUpdated: "Custom JSON updated",

                DownloadEnabledRequest: "Download activation",
                DownloadDisabledRequest: "Disable download",
                SyncNowRequest: "Synchronization request",
                DownloadAuthTokenSecretRequest: "Download auth token secret request",
                MakeAnalyticsNowRequest: "Analytics",
                ClientRemoveByNameRequest: " Client deletion",
                BandRequest: "Updating Wi-Fi band",
                ChannelRequest: "Updating Wi-Fi channel",
                SsidRequest: "Updating SSID",
                TxPowerRequest: "Updating Wi-Fi transmit power",
                RadioEnabledRequest: "Radio activation",
                RadioDisabledRequest: "Radio deactivation",
                WpaPasswordRequest: "Updating WPA password",
                BssUtilizationRequest: "Updating bss utilization",
                ObssUtilizationRequest: "Updating obss utilization",
                IdleRequest: "Updating idle",
                AllowedPortsUdpRequest: "Updating allowed UDP ports",
                AllowedPortsTcpRequest: "Updating allowed TCP ports",
                InternetBlockingEnabledRequest: "Enabling internet blocking",
                InternetBlockingDisabledRequest: "Disabling internet blocking",
                AutoCaptivePortalEnabledRequest: "Enable automatic captive portal",
                AutoCaptivePortalDisabledRequest: "Disable automatic captive portal",
                InternetWhitelistRequest: "Updating internet whitelist",
                WelcomepageZipUrlRequest: "Updating welcome page zip url",
                MediaspotNameRequest: "Updating mediaspot name",
                MediacenterHostRequest: "Updating mediacenter host",
                Tr069HostRequest: "Updating TR069 host",
                WebsocketHostRequest: "Updating websocket server host",
                FirmwareUpdateUrlRequest: "Updating firmware update url",
                MediaspotCustomInfoRequest: "Updating mediaspot custom JSON",

                ContentProviders: "Content providers",
                WifiInterfaces: "Wifi interfaces",
                Firewall: "Firewall",
                Welcomepage: "Welcome page",
                StatusMonitor: "Status monitor",
                AdvancedParameters: "Advanced parameters",
                CustomData: "Custom data",

                // Administration device search
                Type: "Type",
                Associated: "Associated",
                Radiameter: "Radiameter",
                radiameter: "radiameter",
                Search: "Search",
                All: "All",
                AnErrorOccurred: "An error occurred",
                AnErrorOccurredPleaseReload: "An error occurred, please reload",
                AnErrorOccurredDuringLoading: "An error occurred during loading",
                Reload: "Reload",
                SearchResultsForTypes: "Search results for types",
                SearchResultsForType: "Search results for type",
                AssociatedOnly: "associated only",
                NotAssociatedOnly: "not associated only",
                WithReference: "with reference",
                WithSerial: "with serial",
                AnyDeviceMatchingWithSearchFilters: "No device matching with search filters",
                YouHaveToPickAtLeastOneTypeOfDevice: "You have to pick at least one type of device",

                AreaLabelNotConform: "Area label contains unauthorized characters.",
                AreYouSureYouWantToReplace: "Are you sure you want to replace",
                With: "with",
                Percent: "Percent",

            }
        },
        fr: {
            translations: {
                Serial: "Numéro de série",
                Reference: "Réference",
                Yes: "Oui",
                No: "Non",
                NoDataOnThisPeriod: "Pas de données sur cette période",
                Confirmation: "Confirmation",
                AreYouSureYouWantToDissociate: "Êtes-vous sûre de vouloir dissocier",
                PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Veuillez sélectionner une zone de réserve où mettre l'appareil que vous souhaitez dissocier",
                Dissociate: "Dissocier",

                // Interval selector
                PerHour: "Par heure",
                PerDay: "Par jour",
                d: 'j',

                // Date range picker
                SelectPeriod: "Selectionnez une période",
                Last24Hours: "Dernières 24h",
                Last48Hours: "Dernières 48h",
                Last7Days: "7 derniers jours",
                LastMonth: "Mois dernier",
                LastYear: "An dernier",

                AreYouSureYouWantToRemoveThisArea: "Êtes-vous sûre de vouloir supprimer cette zone ?",
                AreYouSureYouWantToRemoveThisDevice: "Êtes-vous sûre de vouloir supprimer cet équipement ?",
                AreYouSureYouWantToRemoveThisMediaspot: "Êtes-vous sûre de vouloir supprimer ce mediaspot. L'ensemble des modacs y étant associés vont également être dissociés.",

                DropThisMediaspotOnABusToBindIt: "Déplacer ce mediaspot sur un bus pour le lier",
                DropThisRouterOnAMediaspotToBindIt: "Déplacer ce routeur sur un mediaspot pour le lier",
                DropThisModacOnAMediaspotToBindTt: "Déplacer ce modac sur un mediaspot pour le lier",
                DropThisSimOnARouterToBindIt: "Déplacer cette SIM sur un routeur pour la lier",
                
                DownloadEnabledUpdated: "Téléchargement activé",
                DownloadDisabledUpdated: "Téléchargement désactivé",
                SyncNowUpdated: "Synchronisation en cours",
                DownloadAuthTokenSecretUpdated: "Secret d'authentification basé sur des tokens à jour",
                MakeAnalyticsNowUpdated: "Analytiques démarrés",
                ClientRemoveByNameUpdated: "Client supprimé",
                BandUpdated: "Bande à jour",
                ChannelUpdated: "Canal à jour",
                SsidUpdated: "SSID à jour",
                TxPowerUpdated: "Puissance de transmission à jour",
                RadioEnabledUpdated: "Radio activée",
                RadioDisabledUpdated: "Radio désactivée",
                WpaPasswordUpdated: "Clé WPA à jour",
                BssUtilizationUpdated: "Utilisation du canal bss à jour",
                ObssUtilizationUpdated: "Utilisation du canal obss à jour",
                IdleUpdated: "Canal inactif mis à jour",
                AllowedPortsUdpUpdated: "Ports UDP sortants autorisés à jour",
                AllowedPortsTcpUpdated: "Ports TCP sortants autorisés à jour",
                InternetBlockingEnabledUpdated: "Blocage d'internet activé",
                InternetBlockingDisabledUpdated: "Blocage d'internet désactivé",
                AutoCaptivePortalEnabledUpdated: "Portail captif automatique activé",
                AutoCaptivePortalDisabledUpdated: "Portail captif automatique désactivé",
                InternetWhitelistUpdated: "Whitelist internet mis à jour",
                WelcomepageZipUrlUpdated: "URL du zip de la page de bienvenue à jour",
                MediaspotNameUpdated: "Nom du mediaspot à jour",
                MediacenterHostUpdated: "Nom d'hôte du mediacenter à jour",
                Tr069HostUpdated: "Nom d'hôte du TR069 mis à jour",
                WebsocketHostUpdated: "Nom d'hôte du serveur websocket mis à jour",
                FirmwareUpdateUrlUpdated: "Demande de mis à jour du firmware OK",
                MediaspotCustomInfoUpdated: "JSON personnalisé à jour",

                DownloadEnabledRequest: "Activation du téléchargement",
                DownloadDisabledRequest: "Désactivation du téléchargement",
                SyncNowRequest: "Demande de synchronisation",
                DownloadAuthTokenSecretRequest: "Mise à jour du secret d'authentification basé sur des tokens",
                MakeAnalyticsNowRequest: "Mise à jour des analytiques",
                ClientRemoveByNameRequest: "Suppression du client",
                BandRequest: "Mise à jour de la bande Wi-Fi",
                ChannelRequest: "Mise à jour du canal Wi-Fi",
                SsidRequest: "Mise à jour du SSID",
                TxPowerRequest: "Mise à jour de la puissance de transmission",
                RadioEnabledRequest: "Activation radio",
                RadioDisabledRequest: "Désactivation radio",
                WpaPasswordRequest: "Mise à jour de la clé WPA",
                BssUtilizationRequest: "Mise à jour de l'utilisation du canal bss",
                ObssUtilizationRequest: "Mise à jour de l'utilisation du canal obss",
                IdleRequest: "Mise à jour du canal inactif",
                AllowedPortsUdpRequest: "Mise à jour des ports UDP sortants autorisés",
                AllowedPortsTcpRequest: "Mise à jour des ports TCP sortants autorisés",
                InternetBlockingEnabledRequest: "Activation du blocage d'internet",
                InternetBlockingDisabledRequest: "Désactivation du blocage d'internet",
                AutoCaptivePortalEnabledRequest: "Activation du portail captif automatique",
                AutoCaptivePortalDisabledRequest: "Désactivation du portail captif automatique",
                InternetWhitelistRequest: "Mise à jour de la whitelist internet",
                WelcomepageZipUrlRequest: "Mise à jour de l'URL du zip de la page de bienvenue",
                MediaspotNameRequest: "Mise à jour du nom du mediaspot",
                MediacenterHostRequest: "Mise à jour du nom d'hôte du mediacenter",
                Tr069HostRequest: "Mise à jour du nom d'hôte du TR069",
                WebsocketHostRequest: "Mise à jour du nom d'hôte du serveur websocket",
                FirmwareUpdateUrlRequest: "Demande de mis à jour du firmware",
                MediaspotCustomInfoRequest: "Mise à jour du JSON personnalisé",

                ContentProviders: "Content providers",
                WifiInterfaces: "Interfaces Wifi",
                Firewall: "Pare-feu",
                Welcomepage: "Welcome page",
                StatusMonitor: "Moniteur d'état",
                AdvancedParameters: "Paramètres avancés",
                CustomData: "Données personnalisées",

                // Administration device search
                Type: "Type",
                Associated: "Associé",
                Radiameter: "Radiamètre",
                radiameter: "radiamètre",
                Search: "Rechercher",
                All: "Tous",
                AnErrorOccurred: "Une erreur s'est produite",
                AnErrorOccurredPleaseReload: "Une erreur s'est produite, veuillez recharger",
                AnErrorOccurredDuringLoading: "Une erreur s'est produite pendant le chargement",
                Reload: "Recharger",
                SearchResultsForTypes: "Résultats de recherche pour les types",
                SearchResultsForType: "Résultats de recherche pour le type",
                AssociatedOnly: "associé uniquement",
                NotAssociatedOnly: "non associés uniquement",
                WithReference: "avec la référence",
                WithSerial: "avec le n° de série",
                AnyDeviceMatchingWithSearchFilters: "Aucun appareil correspondant aux filtres de recherche",
                YouHaveToPickAtLeastOneTypeOfDevice: "Vous devez sélectionner au moins un type d'appareil",

                AreaLabelNotConform: "Le nom de la zone contient des caractères non autorisés",
                AreYouSureYouWantToReplace: "Êtes-vous sûre de vouloir remplacer",
                With: "avec",
                Percent: "Pourcentage",

            }
        }
    },
    load: "languageOnly",
    fallbackLng: "en",
    debug: false,
    lng: localStorage.getItem("language"),
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
