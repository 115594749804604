import React, {Component} from "react"
import classes from "./Header.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faUser} from "@fortawesome/free-solid-svg-icons";
import { getUsername, logout } from "../../api/Auth";

class Header extends Component {

    state = {
        isProfileSubmenuDisplayed: false,
        wrapperRef: React.createRef<HTMLDivElement>(),
        username: ""
    }

    constructor(props: any) {
        super(props);

        //this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        this.setState({
            username: getUsername()
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: any) => {
        if (this.state.wrapperRef && !this.state.wrapperRef.current!.contains(event.target)) {
            if(this.state.isProfileSubmenuDisplayed === true){
                this.setState({isProfileSubmenuDisplayed: false})
            }
        }
    }

    render() {

        return (
            <div ref={this.state.wrapperRef} className={classes.HeaderContainer}>

                <div className={classes.HeaderProfileContainer} onClick={() => { this.setState({ isProfileSubmenuDisplayed: !this.state.isProfileSubmenuDisplayed}) }
                }>
                    <div className={classes.HeaderProfileContent}>
                        {/*<img className={classes.HeaderProfileButton} alt={"profile"} src={ProfileImage}/>*/}
                        <FontAwesomeIcon size={"1x"} icon={faUser}/>
                        <label><FontAwesomeIcon icon={faChevronDown}/></label>
                        <label style={{display: "block"}}>{this.state.username}</label>
                    </div>

                </div>

                {this.state.isProfileSubmenuDisplayed === true ?
                    <>

                    <div className={classes.HeaderLogoutContainer} onClick={() => {
                        if (window.confirm("Etes-vous sûre de vouloir vous déconnecter ?")) {
                            logout()
                            window.location.replace("/")
                        }
                    }}>
                            <div className={classes.HeaderLogoutContent}>
                                <label style={{display: "block"}}>Déconnexion</label>
                            </div>
                    </div>
                    </>
                :undefined}
            </div>
        )
    }
}

export default Header;
