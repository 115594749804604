import { Router } from "../../models/Router";
import classes from "../Statistics.module.css"
import {Trans} from "react-i18next"

interface IProps {
    router: Router
}

export const RouterInfo = (props: IProps) => {

    return (
        <div className={classes.StatsGroupContent}>     
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Serial</Trans></label>
                <label>{props.router.serial}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Brand</Trans></label>
                <label>{props.router.brand}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Model</Trans></label>
                <label>{props.router.model}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Log type</Trans></label>
                <label>{props.router.log_type}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>Reference</Trans></label>
                <label>{props.router.reference}</label>
            </div>
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>RUTX Batch</Trans></label>
                <label>{props.router.rutx_batch}</label>
            </div>

            {/* <div className={[classes.StatsKeyValueContainer, (props.router.configSet.imsi !== props.router.configDetected.imsi ? classes.StatsKeyValueContainerBad : undefined)].join(" ")}>
                <label><Trans>IMSI (expected / detected)</Trans></label>
                <label>{props.router.configSet.imsi} / {props.router.configDetected.imsi}</label>
            </div> */}
            <div className={classes.StatsKeyValueContainer}>
                <label><Trans>IMSI</Trans></label>
                <label>{props.router.configDetected.imsi}</label>
            </div>

            <div className={[classes.StatsKeyValueContainer, (props.router.configSet.rutx_firmware !== props.router.configDetected.rutx_firmware ? classes.StatsKeyValueContainerBad : undefined)].join(" ")}>
                <label><Trans>RUTX firmware (expected / detected)</Trans></label>
                <label>{props.router.configSet.rutx_firmware} / {props.router.configDetected.rutx_firmware}</label>
            </div>

            <div className={[classes.StatsKeyValueContainer, (props.router.configSet.rutx_mccmnc !== props.router.configDetected.rutx_mccmnc ? classes.StatsKeyValueContainerBad : undefined)].join(" ")}>
                <label><Trans>RUTX MCC MNC (expected / detected)</Trans></label>
                <label>{props.router.configSet.rutx_mccmnc} / {props.router.configDetected.rutx_mccmnc}</label>
            </div>
            
        </div>
    )
}