import axios from "axios"
import config from "../config"
import {getToken, logout} from "./Auth"
import {isTokenExpired} from "./Auth"

const api = axios.create({
    baseURL: config.API_URL
})

api.interceptors.request.use((axiosConfig) => {
    axiosConfig.headers["fp-token"] = getToken()
    axiosConfig.headers["x-api-key"] = config.API_KEY

    if(getToken() !== null && isTokenExpired(getToken())){
        logout()
        window.location.replace("/")
        throw new axios.Cancel('Cancel request');
    }

    if(getToken() === null){
        console.log("request not executed because of expired token")
        throw new axios.Cancel('Cancel request');
    }

    return axiosConfig
}, (error) => {
    return Promise.reject(error);
})

export default api
