import { ChartConfiguration } from 'chart.js';
import 'chartjs-plugin-zoom'
import { Interval } from '../../../navigation/tabs/Tabs';
import { Thresold } from '../../Thresold';
import xAxeTimeDefaultProps from '../XAxeTimeDefaultProps';


const excellentThresold: Thresold = {
    key: "EXCELLENT",
    color: "green",
    text: "Excellent signal",
    labelXOffset: 0,
    value: -65
}

const goodThresold: Thresold = {
    key: "GOOD",
    color: "#E4CF00",
    text: "",
    labelXOffset: 0,
    value: -75
}

const warningThresold: Thresold = {
    key: "WARNING",
    color: "orange",
    text: "",
    labelXOffset: 0,
    value: -85
}

const limitThresold: Thresold = {
    key: "POOR",
    color: "red",
    text: "Signal pauvre",
    labelXOffset: 0,
    value: -95
}

export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number): ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        options: {
            plugins:{
                zoom: false,
            },
            responsive:true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [
                    {
                        ticks: {
                            stepSize: 10,
                            min: -110,
                            max: -50
                        }
                    }
                ]
            },
            annotation: {
                events: ["click", "mouseenter", "mouseleave"],
                annotations: [excellentThresold, goodThresold, warningThresold, limitThresold].map((it: Thresold) => {
                    return {
                        drawTime: "afterDatasetsDraw",
                        id: it.key,
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: it.value,
                        borderColor: it.color,
                        borderWidth: 1,
                        label: {
                            backgroundColor: "black",
                            content: it.text,
                            enabled: true
                        }
                    }
                })
            }
        }
    }
    
}
