import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"
import { TemperatureEvolutionApiResponse } from "../../../api/MaintenanceRequests"

export const getTemperatureEvolutionDataset = (data: TemperatureEvolutionApiResponse): EvolutionDataset => {
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "Température (°C)",
        lineTension: 0,
        data: []
    };
    
    const dates:Array<Date> = new Array<Date>()
    
    data.forEach(it => {     
        if(it.temperature !== null){
           
            const dateString:string = it.date
            const date:Date = new Date(dateString)
            
            dates.push(date);
        
            (dataset.data as ChartPoint[]).push({
                x: date,
                y: Math.round(it.temperature)

            })
        }
    })

    return {
        datasets: [dataset],
        dates: dates,
        error: undefined,
        labels: undefined,
        maxValue: undefined
    }
}