// ------------------
// ---- Requests ----
// ------------------

import { getIntervalString, Interval } from "../navigation/tabs/Tabs"
import { getClientUUID } from "./Auth"
import { ApiDataResponse, post } from "./Request"

export const getGSMSignalEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial: string): Promise<ApiDataResponse<GSMSignalEvolutionResponse>> => {
    //alert("get signal")
    const response = await post<GSMSignalEvolutionResponse>("getmobilesignalevolution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        interval: getIntervalString(interval),
        clientUUID: getClientUUID()
    })

    return response
}

export const getDataConsumptionEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<DataConsumptionEvolutionResponse>> => {
    const response = await post<DataConsumptionEvolutionResponse>("getconsumeddataevolution", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        interval: getIntervalString(interval),
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })
    
    return response
}


// -------------------
// ---- Types def ----
// -------------------

/***
 * Evolution of gsm signal 
 */
export type GSMSignalEvolutionResponse = Array<{
    date: string,   
    value: number 
}>

/***
 * Evolution of data consumption
 */
export type DataConsumptionEvolutionResponse = Array<{
    date: string,   
    used: number,
    totalUsed: number
}>