import { useState } from "react"
import classes from "./Mediaspots.module.css"
import Tree from "../../navigation/tree/Tree"
import { Area } from "../../navigation/tree/Area"
import MediaspotManagement from "./management/MediaspotManagement"

const Mediaspots = () => {

    const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [_, setSelectedArea] = useState<Area|undefined>(undefined)

    const [selectedMediaspotSerial, setSelectedMediaspotSerial] = useState<string|undefined>(undefined)
    
    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        
        if(area.hasMediaspot === true && area.mediaspot){
            setSelectedMediaspotSerial("FFFFFF-Mediaspot-" + area.mediaspot.serial)
        }else {
            setSelectedMediaspotSerial(undefined)
        }
    }

    return (
        <>
            <div className={classes.MediaspotsTreeContainer}>
                <Tree
                    autoExpandAllAreas={true}
                    isAreaManagement={false}
                    hideVisibleNodes={true}
                    onFactoriesLoaded={() => console.log("factories loaded")}
                    onModacFetched={() => {console.log("modac fetched")}}
                    onSelectModac={() => {console.log("select modac")}}
                    alreadySelectedNodeId={selectedNodeId}
                    onSelect={onSelectArea} />
            </div>

            <div className={classes.MediaspotsContainer}>
                {
                    selectedMediaspotSerial ? 
                    <MediaspotManagement mediaspotId={selectedMediaspotSerial}/>
                    : <label>Veuillez sélectionnez un bus équipé d'un mediaspot</label>
                }
            </div>
        </>
    )
}

export default Mediaspots