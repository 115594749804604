import { ChartConfiguration } from 'chart.js';

export const graphProps = (): ChartConfiguration => {
    return {
        type: 'bar',
        options: {
            plugins:{
                zoom: false,
            },
            responsive:true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            min: 0,
                            stepSize: 1
                        }
                    }
                ]
            }
        }
    }
    
}
