import React, {Component} from "react"
import Tree from "../navigation/tree/Tree"
import classes from "./DevicesMapping.module.css"
//import DeviceSearch from "./DeviceSearch"
import {Trans} from "react-i18next";
import DeviceSearch from "../devicessearch/DevicesSearch";
import { Area } from "../navigation/tree/Area";
import { NodeType } from "../navigation/tree/NodeType";
import MediaspotsProvisionning from "../superadmin/provisionning/MediaspotsProvisionning";
import RoutersProvisionning from "../superadmin/provisionning/RoutersProvisionning";
import SimProvisionning from "../superadmin/provisionning/SimProvisionning";
import { Router } from "../models/Router";
import { Sim } from "../models/Sim";
import { Mediaspot } from "../models/Mediaspot";

interface IProps {

}

interface IState {
    isDragging: boolean,

    hideVisibleNodes: boolean,
    hideStoreNodes: boolean,
    hideArchiveDevices: boolean,

    deviceToFocus: Mediaspot | Router | Sim | undefined,
    timeoutId: NodeJS.Timeout | undefined,
    toDissociateNodes: Array<Area>| undefined,
    deviceToEdit: Mediaspot | Router | Sim | undefined
}
class DevicesMapping extends Component<IProps, IState> {

    
    constructor(props: IProps){
        super(props)
        this.state = {
            isDragging: false,
            hideVisibleNodes: true,
            hideStoreNodes: true,
            hideArchiveDevices: true,
    
            deviceToFocus: undefined,
            timeoutId: undefined,
            toDissociateNodes: undefined,
            deviceToEdit: undefined
        }
    }

    componentDidMount() {
        document.addEventListener("dragstart", this.onDragStateUpdate)
        document.addEventListener("dragStart", this.onDragStateUpdate)
        document.addEventListener("dragend", this.onDragStateUpdate)
    }

    componentWillUnmount() {
        document.removeEventListener('dragstart', this.onDragStateUpdate);
        document.removeEventListener('dragend', this.onDragStateUpdate);
    }

    onDragStateUpdate = (event: Event) => {
        console.log(event)
        this.setState({isDragging: event.type === "dragstart"})
    }

    onDevicesDissociated = (dissociatedNodes: Array<Area>) => {
        this.setState({toDissociateNodes: dissociatedNodes})
    }

    onDissociationFinished = () => {
        this.setState({toDissociateNodes: undefined})
    }

    onShowDeviceRequest = async(deviceToFocus: Mediaspot | Router | Sim) => {
        if(this.state.timeoutId !== undefined){
            clearInterval(this.state.timeoutId)
        }
        const timeoutId = setTimeout(() => {
            this.setState({deviceToFocus: undefined})
        }, 2000)
        this.setState({deviceToFocus: deviceToFocus, timeoutId: timeoutId})

    }


    onEditDeviceHandler = async(deviceToEdit: any) => {
        this.setState({deviceToEdit: deviceToEdit})
    }

    render(){
        console.log(this.state.deviceToEdit )
        return (
            <div>
                <div className={classes.AdminAreasContainer}>
                    <div className={classes.AdminAreasColumns}>
                        <div className={classes.AdminAreasTreeContainer} style={this.state.isDragging === true ? {border: "2px dashed black", padding: "6px"} : undefined}>
                            <div className={classes.AdminAreasColumnsHeader}>
                                <label className={classes.AdminAreasHeaderLabel}>Périmètre</label>
                                {/*<button className={classes.AdminAreasHideVisibleNodeButton} onClick={() => this.setState({hideVisibleNodes: !this.state.hideVisibleNodes})}><FontAwesomeIcon icon={this.state.hideVisibleNodes === true ? faEyeSlash : faEye} /> <Trans>ShowHideVisibleAreas</Trans></button>*/}
                            </div>

                            <div className={classes.AdminAreasTreeContent}>
                                <Tree 
                                    focusedDevice={this.state.deviceToFocus}
                                    onDevicesDissociated={this.onDevicesDissociated}
                                    autoExpandAllAreas={true}
                                    hideArchiveDevices={this.state.hideArchiveDevices}
                                    hideVisibleNodes={this.state.hideVisibleNodes}
                                    hideStoreNodes={this.state.hideStoreNodes}
                                    enableEdition={true}
                                    isAreaManagement={true}
                                    onSelect={()=>{}}
                                    onSelectModac={()=>{}}/>
                            </div>
                        </div>

                        <div className={classes.AdminDevicesTreeContainer} style={this.state.isDragging === true ? {background: "rgba(0, 0, 0, 0.05"} : undefined}>
                            <div className={classes.AdminAreasColumnsHeader}>
                                <label className={classes.AdminAreasHeaderLabel}>Équipements</label>
                            </div>
                            <DeviceSearch onEditDeviceClick={this.onEditDeviceHandler} onShowDeviceRequest={this.onShowDeviceRequest} toDissociateNodes={this.state.toDissociateNodes} onDissociationFinished={this.onDissociationFinished}/>
                        </div>
                    </div>
                </div>
                {
                    this.state.deviceToEdit === undefined ? null : 
                    <>
                        <div className={classes.PopupOverlay} onClick={() => this.setState({deviceToEdit: undefined})}/>
            
                        <div className={classes.DeviceEditionModalPopupContainer}>
                            <div className={classes.DeviceEditionModalPopupContent}>
                        
                                {(this.state.deviceToEdit as Mediaspot).device_type === NodeType.MEDIASPOT ? <MediaspotsProvisionning device={this.state.deviceToEdit as Mediaspot}/> : undefined}
                                {(this.state.deviceToEdit as Router).device_type === NodeType.ROUTER ? <RoutersProvisionning device={this.state.deviceToEdit as Router}/> : undefined}
                                {(this.state.deviceToEdit as Sim).device_type === NodeType.SIM ? <SimProvisionning device={this.state.deviceToEdit as Sim}/> : undefined}
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default DevicesMapping;
