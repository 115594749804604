import L from 'leaflet';

export const greenBusIcon = new L.Icon({
    
    iconUrl: '/img/bus-green.svg',
    iconRetinaUrl: '/img/bus-green.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const redBusIcon = new L.Icon({
    iconUrl: '/img/bus-red.svg',
    iconRetinaUrl: '/img/bus-red.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const orangeBusIcon = new L.Icon({
    iconUrl: '/img/bus-orange.svg',
    iconRetinaUrl: '/img/bus-orange.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});


