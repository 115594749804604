import { useEffect, useState } from "react"
import classes from "./Statistics.module.css"
import { Redirect, Route, Switch } from "react-router-dom";
import { StatsMedias } from "./medias/StatsMedias";
import { StatsGSM } from "./gsm/StatsGSM";
import { StatsMaintenance } from "./maintenance/StatsMaintenance";
import { Tabs, Interval, TabItem } from "../navigation/tabs/Tabs";
import {subDays} from "date-fns"
import Tree from "../navigation/tree/Tree";
import { Area } from "../navigation/tree/Area";
import { StatsPortal } from "./portal/StatsPortal";

const Statistics = () => {
    
    const items = [
        { label: "Portail", path: "portal" },
        { label: "Médias", path: "medias" },
        { label: "Mobile", path: "gsm" },
        { label: "Maintenance/Réseau", path: "maintenance" }
    ]
    
    const now = Date.now()
    const oneDayAgo = subDays(now, 1)
    oneDayAgo.setHours(0,0,0,0);

    const [selectedTab, setSelectedTab] = useState<TabItem>(items[0])
    const [startPeriodTimestamp, setStartPeriodTimestamp] = useState<number>(oneDayAgo.getTime())
    const [endPeriodTimestamp, setEndPeriodTimestamp] = useState<number>(now)

    const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [selectedNodePath, setSelectedNodePath] = useState<string|undefined>(undefined)
    const [selectedArea, setSelectedArea] = useState<Area|undefined>(undefined)

    const [interval, setInterval] = useState<Interval>(Interval.DAY)

    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        setSelectedNodePath(nodePath)
    }

    useEffect(() => {
        const tab = items.find(it => window.location.pathname === "/supervision/stats/" + it.path) ?? items[0]
        if(JSON.stringify(selectedTab) !== JSON.stringify(tab)){
            setSelectedTab(tab)
        }
    }, [])

    return (
        <>            
            <div className={classes.StatisticsTreeContainer}>
                <Tree
                    autoExpandAllAreas={true}
                    isAreaManagement={false}
                    hideVisibleNodes={true}
                    onFactoriesLoaded={() => console.log("factories loaded")}
                    onModacFetched={() => {console.log("modac fetched")}}
                    onSelectModac={() => {console.log("select modac")}}
                    alreadySelectedNodeId={selectedNodeId}
                    onSelect={onSelectArea} />
            </div>

            <div className={classes.StatisticsTabsContainer}>
                <Tabs
                    interval={interval}
                    onSelect={(newSelectedTab:TabItem) => setSelectedTab(newSelectedTab)}
                    onIntervalChanged={(interval) => setInterval(interval)}
                    onPeriodChange={(dates) => {
                        setStartPeriodTimestamp(dates[0])
                        setEndPeriodTimestamp(dates[1].getTime() + (24 * 60 * 60 * 1000) - 1) // "Returns date at 00h00, so we had seconds to get same date at 23h59
                    }}
                    startDatePeriodTimestamp={startPeriodTimestamp}
                    endDatePeriodTimestamp={endPeriodTimestamp}
                    selectedTab={selectedTab}
                    items={items}
                />
            </div>

            {selectedNodePath !== undefined && selectedArea !== undefined ?
                <div className={classes.StatisticsContainer}>
                    <Switch>
                        <Redirect exact from={"/supervision/stats/"} to={"/supervision/stats/portal"}/>
                        
                        <Route exact path={"/supervision/stats/portal"} render={() => 
                            <StatsPortal 
                                area={selectedArea}
                                nodePath={selectedNodePath}
                                startDateTimestamp={startPeriodTimestamp}
                                endDateTimestamp={endPeriodTimestamp}
                                interval={interval}/>
                        }/>
                        
                        <Route exact path={"/supervision/stats/medias"} render={() => 
                            <StatsMedias
                                area={selectedArea}
                                nodePath={selectedNodePath}
                                startDateTimestamp={startPeriodTimestamp}
                                endDateTimestamp={endPeriodTimestamp}
                                interval={interval}/>
                        }/>

                        <Route exact path={"/supervision/stats/gsm"} render={() => 
                            <StatsGSM
                                area={selectedArea}
                                nodePath={selectedNodePath}
                                startDateTimestamp={startPeriodTimestamp}
                                endDateTimestamp={endPeriodTimestamp}
                                interval={interval}/>    
                        }/>

                        <Route exact path={"/supervision/stats/maintenance"} render={() => 
                            <StatsMaintenance
                                area={selectedArea}
                                nodePath={selectedNodePath}
                                startDateTimestamp={startPeriodTimestamp}
                                endDateTimestamp={endPeriodTimestamp}
                                interval={interval}/>
                        }/>
                    </Switch>
                </div>
                : undefined
            }
        </>    
    )
}

export default Statistics