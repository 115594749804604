import axios, { AxiosResponse } from "axios"
import config from "../config"
import decode from "jwt-decode";
import { Config } from "@fastpoint/mediaspot-management";

export const login = async(username: string, password: string):Promise<{success: Boolean, response?: object, error?: any}> => {
    try {
        // Get a token from api server using the fetch api
        const res = await _fetch(config.API_URL + '/login',
            JSON.stringify({
                username: username,
                password: password
            })
        )

        if(res.success === true){
            await setToken(res.response!.token); // Setting the token in localStorage
        }
        return res
    } catch(ex){
        
        return {
            success: false,
            error: "unknown error"
        }
    }
};

export const refreshToken = async() => {
    const res = await _fetch(config.API_URL + '/refreshtoken',
        JSON.stringify({
            'fp-token': getToken()
        })
    )

    if(res.success === true){
        await setToken(res.response!.token); // Setting the token in localStorage
    }
    return res
}

export const logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
};

export const willTokenExpire = () => {
    // If token will expire in less than 10 minutes, we refresh
    const maxMinutesBeforeRefresh = 10
    if(!loggedIn()){
        return true
    }
    try {
        const token = getToken()
        if(token == null){
            throw new Error('unable to check expiration, token is not set')
        }

        const decoded:any = decode(token);
        const shouldRefreshAt = (decoded.exp) - (60*maxMinutesBeforeRefresh)
        return shouldRefreshAt < (new Date().getTime() / 1000)
        
    } catch (err) {
        console.log("expired check failed: AuthService.js");
        return false;
    }
}

export const getConfirm = () => {
    // Using jwt-decode npm package to decode the token
    const token = getToken()
    if(token != null){
        return decode(token);
    }
};

export const getUsername = (): string => {
    const token = getToken();
    if(token === undefined){
        return ""
    }
    try {
        const token = getToken()
        if(token){
            const decoded:any = decode(token);
            if(decoded !== undefined && decoded.user !== undefined){
                const firstname = decoded.user.firstName
                const lastname = decoded.user.lastName
                return `${(firstname !== undefined && firstname !== null) ? firstname : ""} ${lastname !== undefined && lastname !== null ? lastname : ""}`.trimEnd()
            }
            return ""
        }
        return ""
    } catch(err){
        return ""
    }
}

export const setToken = async(idToken:string) => {
    // Saves user token to localStorage
    await localStorage.setItem("id_token", idToken);
    Config.getInstance().updateToken(idToken)
};

export const getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
};

export const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from localstorage
    return !!token && !isTokenExpired(token); // handwaiving here
};

export const isTokenExpired = (token:string|null) => {
    try {
        if(token === null){
            throw Error("Token is null, unable to check expiration")
        }
        const decoded:any = decode(token);
        return decoded.exp < new Date().getTime() / 1000
    } catch (err) {
        console.log("expired check failed! AuthService.js" + err);
        return true;
    }
};


export const isAdmin = () => {
    if(loggedIn()){
        const token = getToken()
        if(token){
            const decodedToken:any = decode(token)
            if(decodedToken !== undefined && decodedToken.user !== undefined && decodedToken.user.isAdmin === true){
                return true
            }
        }
    }
    return false
}

export const isSuperAdmin = () => {
    if(loggedIn()){
        const token = getToken()
        if(token){
            const decodedToken:any = decode(token)
            if(decodedToken !== undefined && decodedToken.user !== undefined && decodedToken.user.fpAdmin === true){
                return true
            }
        }
    }
    return false
}

export const getClientUUID = () => {
    if(loggedIn()){
        const token = getToken();
        if(token){
            try {
                const decoded:any = decode(token);
                if(decoded !== undefined && decoded.user !== undefined && decoded.user.client !== undefined && decoded.user.client.length > 0 && decoded.user.client[0].clientUUID !== undefined){
                    return decoded.user.client[0].clientUUID
                }
                return ""
            } catch(err){
                return ""
            }
        }
    }
    return false
    
}


const _fetch = async (url: string, options: any): Promise<{success: Boolean, response?: {token: string}, error?: any}> => {
    try {
        // performs api calls sending the required authentication headers
        let headers:any = {
            "Content-Type": "application/json",
            "x-api-key": config.API_KEY,
        };

        if(loggedIn()){
            headers["fp-token"] = getToken()
        }

        const response = await axios.post(url, options,{
            headers: headers,
            ...options
        })
        // Verify if status is OK, if not, throw error
        if(!_checkStatus(response)){
            return { success: false, error: "Invalid credentials"}
        }

        return {success: true, response: response.data}
    } catch (error) {
        return {
            success: false,
            error: ((error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined) ? error.response.data.message : error.message)
        }
    }
};

const _checkStatus = (response:AxiosResponse) => {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
        // Success status lies between 200 to 300
        return true
    } else{
        return false
    }
};