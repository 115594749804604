// ------------------
// ---- Requests ----
// ------------------

import { getClientUUID } from "./Auth"
import { post, ApiDataResponse } from "./Request"

/***
 * Get media ranking with medias/access count list
 */
export const getMediasRanking = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string): Promise<ApiDataResponse<MediaRankingApiResponse>> => {
    const mediasRanking = await post<MediaRankingApiResponse>("getmediasranking", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID()
    })

    return mediasRanking
}


// -------------------
// ---- Types def ----
// -------------------

export type MediaRankingApiResponse = Array<{
    media: string,
    count: number
}>
