import {Config } from "@fastpoint/mediaspot-management"

export default {
    "API_URL": `https://cotentin-api.fast-point.net/${process.env.REACT_APP_STAGE}`,
    "API_KEY": `${process.env.REACT_APP_API_KEY}`
}

Config.getInstance().update({
    API_PROTOCOL: "https",
    API_HOST: `cotentin-api.fast-point.net`,
    API_PORT: `443/${process.env.REACT_APP_STAGE}`,
    API_KEY: `${process.env.REACT_APP_API_KEY}`,
    WEBSOCKET_HOST: "websocket-prod.fp-internal.com",
    WEBSOCKET_PORT: "3052",
})