import { useState } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { Graph, GraphType } from "../../graph/Graph";
import { Interval } from "../../navigation/tabs/Tabs";
import { Area } from "../../navigation/tree/Area";
import classes from "../Statistics.module.css"
import { HTTPErrors } from "./httperrors/HTTPErrors";
import { MediaspotsInfo } from "./MediaspotInfo";
import { TopWebsites } from "./topwebsites/TopWebsites";

interface IProps {
    area: Area
    nodePath: string,
    startDateTimestamp: number,
    endDateTimestamp: number,
    interval: Interval
}

export const StatsMaintenance = (props: IProps) => {
    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState<Layouts>({})

    return (
        <ResponsiveReactGridLayout
                rowHeight={100}
                cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                layouts={layouts}
                onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                    // Check to prevent infinite refresh
                    if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                        setLayouts(allLayouts)
                    }
                }
            }>

            <div className={classes.ChartContainer} key={"stat1"} data-grid={{ w: 6, h: 3, x: 0, y: 0, static: true }}>
                <label>Répartition des navigateurs</label>
                <div className={classes.ChartTitleSeparator}/>
                <div className={classes.ChartGraphContainer}>            
                    <div className={classes.StatisticsGroupContent}>
                        <Graph
                            preventUpdateAnimations={false}
                            graphType={GraphType.BROWSERS_DISTRIBUTION}
                            startDatePeriodTimestamp={props.startDateTimestamp}
                            endDatePeriodTimestamp={props.endDateTimestamp}
                            interval={props.interval}
                            mediaspotSerial={props.area.mediaspot?.serial}
                            nodePath={props.nodePath}/>
                    </div>
                </div>
            </div>

            <div className={classes.ChartContainer} key={"top_websites"} data-grid={{ w: 6, h: 3, x: 6, y: 0, static: true }}>
                <label>Sites les plus visités</label>
                <div className={classes.ChartTitleSeparator}/>
                <div className={classes.ChartGraphContainer}>            
                    <div className={classes.StatisticsGroupContent}>
                        <TopWebsites {...props}/>
                    </div>
                </div>
            </div>

            <div className={classes.ChartContainer} key={"http_errors"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                <label>Erreurs de communications HTTP</label>
                <div className={classes.ChartTitleSeparator}/>
                <div className={classes.ChartGraphContainer}>            
                    <div className={classes.StatisticsGroupContent}>
                        <HTTPErrors {...props}/>
                    </div>
                </div>
            </div>
            

            <div className={classes.ChartContainer} key={"inout_traffic_websites"} data-grid={{ w: 6, h: 3, x: 6, y: 3, static: true }}>
                <label>Traffic entrant/sortant</label>
                <div className={classes.ChartTitleSeparator}/>
                <div className={classes.ChartGraphContainer}>            
                    <Graph
                        preventUpdateAnimations={false}
                        graphType={GraphType.IN_OUT_DATA_EVOLUTION}
                        startDatePeriodTimestamp={props.startDateTimestamp}
                        endDatePeriodTimestamp={props.endDateTimestamp}
                        interval={props.interval}
                        mediaspotSerial={props.area.mediaspot?.serial}
                        nodePath={props.nodePath}/>
                </div>
            </div>

            

            {
                props.area?.mediaspot !== undefined ? 
            
                    <div className={classes.ChartContainer} key={"infos_mediaspot"} data-grid={{ w: 6, h: 3, x: 0, y: 6, static: true }}>
                        <label>Informations mediaspots</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <MediaspotsInfo mediaspot={props.area.mediaspot} />
                    </div>
                    : <div key="empty_1"/>
            }
           
            {
                props.area?.mediaspot !== undefined ? 
                    <div className={classes.ChartContainer} key={"infos_mediaspot_temperature"} data-grid={{ w: 6, h: 3, x: 6, y: 6, static: true }}>
                        <label>Évolution de la température du modem</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>            
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.TEMPERATURE_EVOLUTION}
                                startDatePeriodTimestamp={props.startDateTimestamp}
                                endDatePeriodTimestamp={props.endDateTimestamp}
                                interval={props.interval}
                                mediaspotSerial={props.area.mediaspot.serial}
                                nodePath={props.nodePath}/>
                        </div>
                    </div>: <div key="empty_4"/>
        }                    

        </ResponsiveReactGridLayout>
        
    )
}