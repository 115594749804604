import { useEffect, useState } from "react"
import Bus from "../../models/Bus"
import { useMap, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import classes from "./Locations.module.css"
import {  LatLng } from "leaflet"
import { greenBusIcon, redBusIcon, orangeBusIcon } from "../../helpers/map/Markers"
import Tree from "../../navigation/tree/Tree"
import { Area } from "../../navigation/tree/Area"

interface IProps {
    // busId: string|undefined
}

const ChangeView = ({ center, zoom }: { center:any, zoom:any }) => {
    useMap().setView(center, zoom);
    return null;
  }

const Locations = (props: IProps) => {

    const [locations, setLocations] = useState<undefined|Bus[]>(undefined)
    const [selectedBus] = useState<undefined|Bus>(undefined)
    const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(undefined)

    useEffect(() => {
        // load bus
        // setLocations()
        console.log("reload bus locations")
        setLocations([new Bus("0001", 49.633731, -1.622137, new Date(Date.now()))])
    }, [selectedBus, selectedNodeId])

    
    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
    }

    return (
        <>
            <div className={classes.LocationsTreeContainer}>
                <Tree
                    autoExpandAllAreas={true}
                    isAreaManagement={false}
                    hideVisibleNodes={true}
                    onFactoriesLoaded={() => console.log("factories loaded")}
                    onModacFetched={() => {console.log("modac fetched")}}
                    onSelectModac={() => {console.log("select modac")}}
                    alreadySelectedNodeId={selectedNodeId}
                    onSelect={onSelectArea} />
            </div>

            <div className={classes.LocationsContainer}>
                <div className={classes.ChartContainer}>
                        
                        <label>Position des bus</label>
                        
                        <div className={classes.LocationsTitleSeparator}/>

                        {
                            locations === undefined ? <label>Chargement en cours...</label> :
                        
                                <div className={classes.LocationsMapContainer} style={{height:"800px"}}>
                                    <MapContainer style={{width: "100%", height: "90%" }} center={selectedBus !== undefined ? [selectedBus.getLatitude(), selectedBus.getLongitude()] : [49.633731, -1.622137]} zoom={12} scrollWheelZoom={false}>
                                        <ChangeView center={selectedBus !== undefined ? new LatLng(selectedBus.getLatitude(), selectedBus.getLongitude()) : new LatLng(49.633731, -1.622137)} zoom={13} /> 
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                        {
                                            locations.map(location => {
                                                let lastContactTime = location.getLastContact()?.getTime()
                                                if(lastContactTime){
                                                    const now = Date.now()
                                                    let icon = greenBusIcon
                                                    if(now - lastContactTime > 1000 * 60 * 60 * 12){
                                                        icon = orangeBusIcon
                                                    }else if(now - lastContactTime > 1000 * 60 * 60 * 24){
                                                        icon = redBusIcon
                                                    }
                                                    return (
                                                        <Marker key={location.getId()} icon={icon} position={[location.getLatitude(), location.getLongitude()]}>
                                                            <Popup>
                                                                <b>Bus {location.getId()}</b>
                                                                <br />
                                                                <label>Dernier contact : {location.getLastContact()?.toLocaleString()}</label>
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                return null
                                            })
                                        }
                                        
                                    </MapContainer>
                                </div>
                        }
                </div>
            </div>
        </>
    )
}

export default Locations