import config from "../config"
import { Interval } from "../navigation/tabs/Tabs";
import {ApiDataResponse, postRequest} from "./Request";

export const getWebsocketServerLastContacts = async() => {
    const response = await postRequest(config.API_URL + "/getwsmediaspots")
    if(response.data && response.data.result && response.data.result.data){
        return response.data.result.data
    }
    if(response.error)
        return response
    return {
        error: "Unexpected error"
    }
}

export const getTasks = async(deviceId:string) => {
    return await postRequest(config.API_URL + "/gettasks", {
        device_id: deviceId
    })
}

export const deleteTasks = async(tasks: string[]) => {
    return await postRequest(config.API_URL + "/deletetasks", {
        tasks: tasks
    })
}

export const getRSSIEvolution = async(startDateTimestamp: number, endDateTimestamp: number, interval: Interval, mediaspotSerial: string): Promise<ApiDataResponse<RSSIEvolutionResponse>> => {
    // return await postRequest(config.API_URL + "/deletetasks", {
    //     startDateTimestamp: startDateTimestamp,
    //     endDateTimestamp: endDateTimestamp,
    //     interval: Interval.DAY.toString
    // })

    return {
        error: undefined,
        data:[
        {
            date: "2021-05-17T00:00:00.590Z",
            value: -50
        },
        {
            date: "2021-05-17T02:00:00.590Z",
            value: -60
        },
        {
            date: "2021-05-17T04:00:00.590Z",
            value: -70
        },
        {
            date: "2021-05-17T06:00:00.590Z",
            value: -30
        },
        {
            date: "2021-05-17T08:00:00.590Z",
            value: -20
        }
    ]}
}

export type RSSIEvolutionResponse = Array<{
    date: string,   
    value: number 
}>
