import { ChartConfiguration, ChartData, ChartTooltipItem } from 'chart.js';
import 'chartjs-plugin-zoom'
import i18n from '../../../i18n';
import { Interval } from '../../../navigation/tabs/Tabs';
import xAxeTimeDefaultProps from '../XAxeTimeDefaultProps';

export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number): ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        options: {
            plugins:{
                zoom: false,
            },
            responsive:true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data['datasets'][0]['data'] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                            const dataset = data['datasets'][tooltipItem.datasetIndex!]['data']![tooltipItem['index']] as {x: Date, y: number}
                            const title = data['datasets'][tooltipItem.datasetIndex!].label

                            let value = dataset.y
                            if(value >= 1024 && value <= 1000000){
                                return title + " : " + (dataset.y / 1024).toFixed(1) + "MB";
                            }else if(value >= (1024*1024)){
                                return title + " : " + (dataset.y / (1024*1024)) + "GB";
                            }
                            return title + " : " + dataset.y + "KB";
                        }
                        return ""
                    }
                }
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('MBytes'),
                    },
                    ticks: {
                        min: 0
                    }
                }]
            }
        }
    }
    
}
