import { ChartConfiguration } from 'chart.js';
import 'chartjs-plugin-zoom'
import { Interval } from '../../../navigation/tabs/Tabs';
import xAxeTimeDefaultProps from '../XAxeTimeDefaultProps';

export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number): ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        options: {
            plugins:{
                zoom: false,
            },
            responsive:true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data['datasets'][0]['data'] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                            // TODO: test
                            return 'Mémoire libre : ' + (data.datasets[0].data[tooltipItem.index]  as any)["y"] +'Mo'
                        }
                        return ""
                    }
                }
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ]
            }
        }
    }
    
}
