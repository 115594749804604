import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import {Interval} from "../../../navigation/tabs/Tabs"
import xAxeTimeDefaultProps from "../XAxeTimeDefaultProps"



export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number):ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const props:ChartConfiguration = {
        type: 'line',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                align:"center",
                fullWidth: true,
                labels: {
                    fontColor: "black",
                    usePointStyle: true
                },
                position: "bottom"
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [{
                    ticks: {
                        min: 0,
                        stepSize: 1
                    }
                }]
            }            
        }
    }
    return props
}
