import { useState } from "react"
import classes from "../../stats/Statistics.module.css"
import { Interval } from "../../navigation/tabs/Tabs";
import {subDays} from "date-fns"
import { Area } from "../../navigation/tree/Area";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { TopWebsites } from "../../stats/maintenance/topwebsites/TopWebsites";
import WithTreeTab from "../WithTreeTab";
import { Graph, GraphType } from "../../graph/Graph";
import { getRangeEndDate, getRangeStartDate } from "../../helpers/Storage";

const InternetAccess = () => {

    const endDate = getRangeEndDate() === null ? Date.now() : getRangeEndDate() as number
    const startDate = getRangeStartDate() === null ? subDays(endDate, 1) : new Date(getRangeStartDate() as number)
    startDate.setHours(0,0,0,0);

    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState<Layouts>({})

    const [startPeriodTimestamp, setStartPeriodTimestamp] = useState<number>(startDate.getTime())
    const [endPeriodTimestamp, setEndPeriodTimestamp] = useState<number>(endDate)

    const [_, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [selectedNodePath, setSelectedNodePath] = useState<string|undefined>(undefined)
    const [selectedArea, setSelectedArea] = useState<Area|undefined>(undefined)

    const [interval, setInterval] = useState<Interval>(Interval.DAY)

    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        console.log("select area", area)
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        setSelectedNodePath(nodePath)
    }

    return (
        <WithTreeTab
            items={[]}
            onPeriodChanged={(startDateTimestamp, endDateTimestamp) => {
                setStartPeriodTimestamp(new Date(startDateTimestamp).getTime())
                setEndPeriodTimestamp(new Date(endDateTimestamp).getTime())}}
            onIntervalChanged={(newInterval) => setInterval(newInterval)}
            onSelectArea={onSelectArea}>


                {selectedNodePath !== undefined && selectedArea !== undefined ? (
                    <ResponsiveReactGridLayout
                        rowHeight={100}
                        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                        layouts={layouts}
                        onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                            // Check to prevent infinite refresh
                            if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                                setLayouts(allLayouts)
                            }
                        }}>

                        <div className={classes.ChartContainer} key={"top_websites"} data-grid={{ w: 6, h: 3, x: 0, y: 0, static: true }}>
                            <label>Sites les plus visités</label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>            
                                <div className={classes.StatisticsGroupContent}>
                                    <TopWebsites
                                        area={selectedArea!}
                                        nodePath={selectedNodePath!}
                                        startDateTimestamp={startPeriodTimestamp}
                                        endDateTimestamp={endPeriodTimestamp}
                                        interval={interval}/>
                                </div>
                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"inout_hotspot_traffic_websites"} data-grid={{ w: 6, h: 3, x: 6, y: 0, static: true }}>
                            <label>Traffic entrant/sortant du hotspot</label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>            
                                <Graph
                                    preventUpdateAnimations={false}
                                    graphType={GraphType.IN_OUT_DATA_EVOLUTION}
                                    startDatePeriodTimestamp={startPeriodTimestamp}
                                    endDatePeriodTimestamp={endPeriodTimestamp}
                                    interval={interval}
                                    mediaspotSerial={selectedArea.mediaspot?.serial}
                                    nodePath={selectedNodePath}/>
                            </div>
                        </div>




                        {
                            selectedArea.hasMediaspot === true && selectedArea.mediaspot ?
                                <div className={classes.ChartContainer} key={"gsm_quality_evolution"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                                    <label>Qualité signal GSM</label>
                                    <div className={classes.ChartTitleSeparator}/>
                                    <div className={classes.ChartGraphContainer}>            
                                        <Graph
                                            preventUpdateAnimations={false}
                                            graphType={GraphType.SIGNAL_QUALITY_EVOLUTION}
                                            startDatePeriodTimestamp={startPeriodTimestamp}
                                            endDatePeriodTimestamp={endPeriodTimestamp}
                                            interval={interval}
                                            mediaspotSerial={selectedArea.mediaspot.serial}
                                            nodePath={selectedNodePath}/>
                                    </div>
                                </div>
                                : <div key="empty_0"/>
                        }
                        {
                            selectedArea.hasMediaspot === true && selectedArea.mediaspot ?
                                <div className={classes.ChartContainer} key={"data_usage_evolution"} data-grid={{ w: 6, h: 3, x: 6, y: 3, static: true }}>
                                        <label>Consommation data</label>
                                        <div className={classes.ChartTitleSeparator}/>
                                        <div className={classes.ChartGraphContainer}>            
                                        <Graph
                                            preventUpdateAnimations={false}
                                            graphType={GraphType.DATA_USAGE_EVOLUTION}
                                            startDatePeriodTimestamp={startPeriodTimestamp}
                                            endDatePeriodTimestamp={endPeriodTimestamp}
                                            interval={interval}
                                            mediaspotSerial={selectedArea.mediaspot.serial}
                                            nodePath={selectedNodePath}/>
                                        </div>
                                </div> : <div key="empty_1"/>
                        }
                    </ResponsiveReactGridLayout>)
                : <div key="empty_0"/> }
        </WithTreeTab>    
    )
}

export default InternetAccess