import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"
import { Thresold } from "../../Thresold"
import xAxeTimeDefaultProps from "../XAxeTimeDefaultProps"

const warningThresold: Thresold = {
    key: "WARNING",
    color: "orange",
    text: "Seuil d'avertissement",
    labelXOffset: 0,
    value: 60
}

const limitThresold: Thresold = {
    key: "CRITIQUE",
    color: "red",
    text: "Seuil critique",
    labelXOffset: 0,
    value: 80
}



export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number):ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const props:ChartConfiguration = {
        type: 'line',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Temperature (°C)')
                    },
                    ticks: {
                        max: 100,
                        min: 0,
                        stepSize: 20,
                    }
                }]
            },
            annotation: {
                events: ["click", "mouseenter", "mouseleave"],
                annotations: [warningThresold, limitThresold].map((it: Thresold) => {
                    return {
                        drawTime: "afterDatasetsDraw",
                        id: it.key,
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: it.value,
                        borderColor: it.color,
                        borderWidth: 1,
                        label: {
                            backgroundColor: "black",
                            content: it.text,
                            enabled: true
                        }
                    }
                })
            },
        }
    }
    return props
}
