import { ChartConfiguration, ChartData, ChartTooltipItem } from "chart.js"

export const graphProps = ():ChartConfiguration => {
    
    const props:ChartConfiguration = {
        type: 'pie',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                align:"center",
                fullWidth: true,
                position: "bottom"
            },
            // tooltips: {
                // callbacks: {
                //     title(tooltipItem: ChartTooltipItem[], data: ChartData){
                //         // return ""
                //         if(data !== undefined && data["labels"] !== undefined && tooltipItem !== undefined && tooltipItem.length > 0 && tooltipItem[0]['index'] !== undefined && data['labels'][tooltipItem[0]['index']] !== undefined){
                //             const labels = data.labels
                //             if(labels.length > 0 && labels as Array<string>){
                //                 const index = tooltipItem[0]['index']
                //                 const result = labels[index]
                //                 return result as string
                //             }
                //         }
                //         return ""
                //     },
                //     label: function(tooltipItem, data) {
                //         if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data['datasets'][0]['data'] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                //             return data['datasets'][0]['data'][tooltipItem['index']]+'%';
                //         }
                //         return ""
                //     }
                // }
            // }
        }
    }
    return props
}
