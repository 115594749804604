import React from "react";
import ReactDOM from "react-dom";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import charts from "fusioncharts/fusioncharts.charts";

import zoomline from "fusioncharts/fusioncharts.powercharts";
import { BrowsersRepartitionApiResponse, getBrowsersRepartition } from "../api/MaintenanceRequests";
import { MaterialColors } from "../helpers/Colors";

// Include the theme as fusion
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, charts, zoomline);


const chartConfigs = {
    type: "multilevelpie", // The chart type
    width: "700", // Width of the chart
    height: "270", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
        // Chart Configuration
        chart: {
            theme: "fusion",
            showBorder: false,
            bgAlpha: 0
        },
        category: [
            {
                "label": "Browsers",
                "color": "#ffffff",
                "value": "150",
                "category": [
                    {
                        "label": "Chrome mobile",
                        "color": "#f8bd19",
                        "value": "55.5",
                        "tooltext": "Food & Beverages, $$valueK, $percentValue",
                        "category": [
                            {
                                "label": "89",
                                "color": "#f8bd19",
                                "value": "11.1"
                            },
                            {
                                "label": "90",
                                "color": "#f8bd19",
                                "value": "27.75"
                            },
                            {
                                "label": "91",
                                "color": "#f8bd19",
                                "value": "9.99"
                            },
                            {
                                "label": "92",
                                "color": "#f8bd19",
                                "value": "6.66"
                            }
                        ]
                    },
                    {
                        "label": "Safari",
                        "color": "#33ccff",
                        "value": "42",
                        "tooltext": "Apparel & Accessories, $$valueK, $percentValue",
                        "category": [
                            {
                                "label": "11",
                                "color": "#33ccff",
                                "value": "10.08"
                            },
                            {
                                "label": "12",
                                "color": "#33ccff",
                                "value": "18.9"
                            },
                            {
                                "label": "13",
                                "color": "#33ccff",
                                "value": "6.3"
                            },
                            {
                                "label": "10",
                                "color": "#33ccff",
                                "value": "6.72"
                            }
                        ]
                    },
                    {
                        "label": "Firefox",
                        "color": "#ffcccc",
                        "value": "22.5",
                        "tooltext": "Baby Products, $$valueK, $percentValue",
                        "category": [
                            {
                                "label": "80",
                                "color": "#ffcccc",
                                "value": "9.45",
                                "tooltext": "Bath & Grooming, $$valueK, $percentValue"
                            },
                            {
                                "label": "81",
                                "color": "#ffcccc",
                                "value": "6.3"
                            },
                        ]
                    },
                    {
                        "label": "Others",
                        "color": "#ccff66",
                        "value": "30",
                        "category": [
                            {
                                "label": "Others",
                                "color": "#ccff66",
                                "value": "8.1"
                            },
                            // {
                            //     "label": "Televisions",
                            //     "color": "#ccff66",
                            //     "value": "10.5"
                            // }
                        ]
                    }
                ]
            }
        ],
        // Chart Data
        // data: chartData
    }
};

interface IProps {
    startDateTimestamp:number
    endDateTimestamp:number
    nodePath: string
} 

interface IState {
    browsersRepartition?: BrowsersRepartitionApiResponse
}

class MultiLevelPieChart extends React.Component<IProps, IState> {
    
    constructor(props: IProps){
        super(props)
        this.state = {
            browsersRepartition: undefined
        }
    }

    async componentDidMount(){
        const repartition = await getBrowsersRepartition(this.props.startDateTimestamp, this.props.endDateTimestamp, this.props.nodePath);
        if(repartition.data){
            // alert(repartition.data)
            this.setState({browsersRepartition: repartition.data})
        }
    }
    
    render() {

        const chartConfigs = {
            type: "multilevelpie", // The chart type
            width: "700", // Width of the chart
            height: "270", // Height of the chart
            dataFormat: "json", // Data type
            dataSource: {
                // Chart Configuration
                chart: {
                    theme: "fusion",
                    showBorder: false,
                    bgAlpha: 0
                },
                category: this.state.browsersRepartition !== undefined ? [
                    {
                        "label": "Browsers",
                        "color": "#ffffff",
                        "value": "150",
                        "category": this.state.browsersRepartition.map((it, index) => {
                        return {
                            label: it.browser,
                            color: MaterialColors[index],
                            value: it.count,
                            tooltext: it.browser,
                            category: it.versions.map(it => {
                                return {
                                    label: it.version,
                                    color: MaterialColors[index],
                                    value: it.count
                                }
                            })
                        }
                })}] : []   
            }
        }
        return (<ReactFC {...chartConfigs} />);
    }
}

export default MultiLevelPieChart;
